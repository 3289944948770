/**
 *  SITE INTEGRATION CONSTANTS CONTROL CENTER
 *  In this file the site integration constants of the project are configured.
 *  In this file we will find ONLY the keys used in the My Site > Site Integration view.
 *
 *  WARNINGS:
 *            - When you need to include more than one HTML tag, they must be contained in
 *              a parent HTML tag, for example:
 *              Examples of incorrect code:
 *              (
 *                <h1>Hello World!</h1>
 *                <p>Lorem ipsum ... </p>
 *              )
 *
 *              Examples of correct code:
 *              (
 *                <>
 *                    <h1>Hello World!</h1>
 *                    <p>Lorem ipsum ... </p>
 *                </>
 *              )
 *
 *            - Make sure to preserve both the structure and the indentation, our format is:
 *              {
 *                section_name: {
 *                    static: (... Text|HTML),
 *                    variable: {
 *                        variable_1: (... Text|HTML),
 *                        ...
 *                    },
 *                },
 *                ...
 *              }
 */
import React from 'react';
import OnboardingBox from '../components/Onboarding/OnboardingBox/OnboardingBox';

import noPicture from '../assets/onboardings/noPicture.png';
import WoocommerceClientSecret from '../assets/woocommerce-clientid-secret.gif';
import WoocommerceCustomThankYou from '../assets/woocommerce-customthankyou.gif';
import WoocommerceCustomThankYouSelection from '../assets/woocommerce-customthankyou-selection.gif';
import WoocommerceCustomProductAttribute from '../assets/woocommerce-product-attribute.gif';
import WoocommerceProductNew from '../assets/woocommerce-product-new.gif';
import WoocommerceSettingsPlugin from '../assets/woocommerce-settings-plugin.gif';
import WoocommerceSettingsPluginWP from '../assets/woocommerce-settings-plugin-WP.gif';
import ecommerceWoocommerce from '../assets/onboardings/ecommerceWoocommerce.png';
import cmsSimple from '../assets/onboardings/cms-simple.png';
import tpaFacebook from '../assets/onboardings/tpa-facebook.png';
import tpaGoogle from '../assets/onboardings/tpa-google.png';
import tpaLinkedin from '../assets/onboardings/tpa-linkedin.png';
import tpaMicrosoft from '../assets/onboardings/tpa-microsoft.png';
import tpaSaml from '../assets/onboardings/tpa-saml.png';
import googleAnalytics2 from '../assets/onboardings/google-analytics-2.png';
import mobileApp from '../assets/onboardings/mobile-app.png';
import wordpress from '../assets/onboardings/wordpress.png';
import simpleEcommerce from '../assets/onboardings/simpleEcommerce.png';

import Styles from '../Common.module.scss';

import DOCS_EXTERNAL_THIRD_PARTY_AUTH from './documentation.constants';

import {
  GOTO_MANAGE_SETTINGS_INTEGRATIONS_WEBSITE_CMS_SIMPLE,
  GOTO_MANAGE_SETTINGS_INTEGRATIONS_WEBSITE_WORDPRESS,
  GOTO_MANAGE_SETTINGS_TRACKING_TOOLS_GA,
  GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_SETTINGS,
} from './iframes.constants';

import {
  MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
  NOT_AVAILABLE,
} from './messages.constants';

import {
  GOOGLE_SSO_INTEGRATION_FORM_REQUEST,
  FACEBOOK_SSO_INTEGRATION_FORM_REQUEST,
  LINKEDIN_SSO_INTEGRATION_FORM_REQUEST,
  MICROSOFT_SSO_INTEGRATION_FORM_REQUEST,
  SAML_SSO_INTEGRATION_FORM_REQUEST,
  OAUTH_SSO_INTEGRATION_FORM_REQUEST,
} from './modals.constants';

import {
  INACTIVE_SUBSCRIPTION,
  INACTIVE_STRATUS_SUBSCRIPTION,
  MISCONFIGURED_SUBSCRIPTION,
} from './subscription.constants';

const GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_GOOGLE = (
  <>
    <p>To request SSO with Google you must follow these steps:</p>
    <ol>
      <li>
        Create an API project and enable the correct Google cloud services by
        following
        {' '}
        <a
          href={DOCS_EXTERNAL_THIRD_PARTY_AUTH.google}
          target="_blank"
          rel="noreferrer"
        >
          this document
        </a>
        .
      </li>
      <li>
        Submit the application and required information to edunext using the
        button below.
      </li>
      <li>
        The edunext support team will receive your request and make the
        configurations so that your SSO integration is fully configured and
        tested.
      </li>
    </ol>
    {GOOGLE_SSO_INTEGRATION_FORM_REQUEST}
  </>
);

const GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_FACEBOOK = (
  <>
    <p>To request SSO with Facebook you must follow these steps:</p>
    <ol>
      <li>
        You create and configure a Facebook application following
        {' '}
        <a
          href={DOCS_EXTERNAL_THIRD_PARTY_AUTH.facebook}
          target="_blank"
          rel="noreferrer"
        >
          this document
        </a>
        .
      </li>
      <li>
        You Submit the request and the information required to edunext using the
        button below.
      </li>
      <li>
        The edunext support team will receive your request and perform the
        configurations to get your SSO integration fully configured and tested.
      </li>
    </ol>
    {FACEBOOK_SSO_INTEGRATION_FORM_REQUEST}
  </>
);

const GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_LINKEDIN = (
  <>
    <p>To request SSO with LinkedIn you must follow these steps:</p>
    <ol>
      <li>
        You create and configure a linkedin application following
        {' '}
        <a
          href={DOCS_EXTERNAL_THIRD_PARTY_AUTH.linkedin}
          target="_blank"
          rel="noreferrer"
        >
          this document
        </a>
        .
      </li>
      <li>
        You Submit the request and the information required to edunext using the
        button below.
      </li>
      <li>
        The edunext support team will receive your request and perform the
        configurations to get your SSO integration fully configured and tested.
      </li>
    </ol>
    {LINKEDIN_SSO_INTEGRATION_FORM_REQUEST}
  </>
);

const GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_MICROSOFT = (
  <>
    <p>To request SSO with Microsoft you must follow these steps:</p>
    <ol>
      <li>
        You create and configure a your Azure AD settings following
        {' '}
        <a
          href={DOCS_EXTERNAL_THIRD_PARTY_AUTH.microsoft}
          target="_blank"
          rel="noreferrer"
        >
          this document
        </a>
        .
      </li>
      <li>
        You Submit the request and the information required to edunext using the
        button below.
      </li>
      <li>
        The edunext support team will receive your request and perform the
        configurations to get your SSO integration fully configured and tested.
      </li>
    </ol>
    {MICROSOFT_SSO_INTEGRATION_FORM_REQUEST}
  </>
);

const GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_SAML = (
  <>
    <p>
      When you use the SAML 2.0 protocol to enable single sign-on (SSO),
      security tokens containing assertions pass information about an end-user
      (principal) between a SAML authority - an identity provider (IdP), and a
      SAML consumer - a service provider (SP). In this case, your external
      system will act as the IdP and the Open edX LMS will act as the SP.
    </p>
    <p>This process will require the following steps:</p>
    <ol>
      <li>You start your request using the button below.</li>
      <li>
        The edunext support team will receive your request and perform the
        configurations to publish the metadata file for the LMS service.
      </li>
      <li>
        You will receive via email the url of the LMS metadata file, along with
        the detailed instructions in
        {' '}
        <a
          href={DOCS_EXTERNAL_THIRD_PARTY_AUTH.saml}
          target="_blank"
          rel="noreferrer"
        >
          this document
        </a>
        , which you need to follow on your SAML side to enable the service and
        configure the profile fields sent over the SAML assertion.
      </li>
      <li>
        You will send back to edunext, via the corresponding support ticket the
        requested information.
      </li>
      <li>
        The edunext support team will wrap up the configurations to get your SSO
        integration fully configured and tested.
      </li>
    </ol>
    {SAML_SSO_INTEGRATION_FORM_REQUEST}
  </>
);

const GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_OAUTH = (
  <>
    <p>Enable SSO with Oauth2.0 OIDC</p>
    <p>
      The OpenID Connect 1.0 is a simple identity layer on top of the OAuth 2.0
      protocol. It allows Clients to verify the identity of the End-User based
      on the authentication performed by an Authorization Server, as well as to
      obtain basic profile information about the End-User in an interoperable
      and REST-like manner.
    </p>
    <p>
      OpenID Connect is a very flexible standard and supports many
      authentication flows. The current version of Open edX only supports the
      most popular authentication flow which is the “authorization_code”
      flow[1].
    </p>
    <p>
      [1] https://openid.net/specs/openid-connect-core-1_0.html#CodeFlowAuth
    </p>
    <p>
      In this case, your external system will act as the IdP and the Open edX
      LMS will act as the SP.
    </p>
    <p>
      <b>NOTE:</b>
      {' '}
      This type of integration works very well, but requires a high
      level of technical skill on your side to perform the initial configuration
      and troubleshoot any particular issue over time. In case that the required
      expertise is not available on your team, it is best not to follow this
      integration path.
    </p>
    <p>This process will require the following steps:</p>
    <ol>
      <li>
        You perform the configurations on the OIDC backend and prepare the
        information requested in
        {' '}
        <a
          href={DOCS_EXTERNAL_THIRD_PARTY_AUTH.oauth}
          target="_blank"
          rel="noreferrer"
        >
          this document
        </a>
        .
      </li>
      <li>
        You Submit the request and the information required to edunext using the
        button below.
      </li>
      <li>
        The edunext support team will receive your request and perform the
        configurations to get your SSO integration fully configured and tested.
      </li>
    </ol>
    {OAUTH_SSO_INTEGRATION_FORM_REQUEST}
  </>
);

const GOTO_MANAGE_SETTINGS_WOOCOMMERCE = (
  <>
    <h5 className="text-center">Configuration Steps</h5>
    <br />
    <div className="d-flex align-items-center">
      <div style={{ width: '50%', paddingRight: '20px' }}>
        <p>
          Install WC custom thank you plugin.
          <br />
          Go to Plugins and install the WC custom thank you page. This plugin
          will allow customizing the page where a thanks message is shown after
          users make a payment.
        </p>
      </div>
      <img
        src={WoocommerceCustomThankYou}
        alt=""
        style={{ width: '50%', maxWidth: 'none' }}
      />
    </div>

    <br />
    <br />

    <h5 className="text-center">Client ID and Client Secret</h5>
    <br />
    <div className="d-flex align-items-center">
      <img
        src={WoocommerceClientSecret}
        alt=""
        style={{ width: '50%', maxWidth: 'none' }}
      />
      <div style={{ width: '50%', paddingLeft: '20px' }}>
        <p>
          Go to Open edX WP-integrator- General settings, and add the client ID
          and client secret.
        </p>
        <p>
          To enable and configure the EOX APIs, you can visit the following
          <span>Page</span>
          .
        </p>
      </div>
    </div>

    <br />
    <br />

    <h5 className="text-center">
      WooCommerce settings in the Open edX LMS and WordPress integrator Plugin
    </h5>
    <br />
    <div className="d-flex align-items-center">
      <div style={{ width: '50%', paddingRight: '20px' }}>
        <p>
          Go to Open edX WP-integrator- WooCommerce Integration, and enable the
          following options.
        </p>
        <p>Enable the woocommerce integration</p>
        <p>Woocommerce trigger action: WooCommerce payment complete.</p>
        <p>
          Default enrollment fulfillment action: Process request (recommended).
        </p>
        <p>
          User Profile fields mapping: This is an advanced feature only
          supported for edunext customers. Mapping of user fields for
          pre-filling, from Open-edX (extended_profile) to Woocommerce
          (checkout)
        </p>
        <code>
          {JSON.stringify({
            billing_email: 'email',
            billing_first_name: 'name',
          })}
        </code>
        <br />
        <br />
        <p>
          Assert users are logged in to see the cart: If you want to force users
          to log in before seeing the cart page.
        </p>
        <p>
          Lms redirect path for the cart: Path that passed into the lms ?next=
          parameter takes the user back to the woocommerce cart.
        </p>
        <p>
          Get username information from Open edX into the checkout page: The
          email address will be automatically populated in the checkout form as
          long as the user is logged in.
        </p>
        <p>
          Lms redirect path for the checkout form: Path that passed into the lms
          ?next= parameter takes the user back to the woocommerce checkout form.
        </p>
        <p>
          User Profile fields mapping for client-side pre-filling: Mapping of
          user fields for pre-filling, from the accounts api in Open edX to the
          to Woocommerce checkout form fields.
        </p>
        <code>
          {JSON.stringify({
            billing_email: 'email',
            billing_first_name: 'name',
          })}
        </code>
        <br />
      </div>
      <img
        src={WoocommerceSettingsPlugin}
        alt=""
        style={{ width: '50%', maxWidth: 'none' }}
      />
    </div>

    <br />
    <br />

    <h5 className="text-center">Settings in the WooCommerce plugin</h5>
    <br />
    <div className="d-flex align-items-center">
      <img
        src={WoocommerceSettingsPluginWP}
        alt=""
        style={{ width: '50%', maxWidth: 'none' }}
      />
      <div style={{ width: '50%', paddingLeft: '20px' }}>
        <p>
          Go to WooCommerce-Settings on the left panel, and adjust the following
          options:
        </p>
        <h6>Accounts and privacy</h6>
        <p>
          Guess checkout: Allow customers to place orders without an account
        </p>
        <h6>Account creation</h6>
        <p>
          All these options must be disabled since we would be using the Open
          edX session and not the WordPress one.
        </p>
        <h6>Account erasure requests</h6>
        <p>Check the options:</p>
        <ul>
          <li>Remove personal data from orders on request.</li>
          <li>Remove access to downloads on request</li>
          <li>
            Personal data removal - Allow personal data to be removed in bulk
            from orders
          </li>
        </ul>
      </div>
    </div>

    <br />
    <br />

    <h5 className="text-center">Establish the Thank you page</h5>
    <br />
    <div className="d-flex align-items-center">
      <div style={{ width: '50%', paddingRight: '20px' }}>
        <p>
          1. Create the page where the thanks for your purchase message will be
          placed.
        </p>
        <p>
          2. Go to WooCommerce-Settings on the left panel, and adjust the
          following option:
        </p>
        <ul>
          <li>Advanced</li>
        </ul>
        <p>Select the thank you page you just created.</p>
      </div>
      <img
        src={WoocommerceCustomThankYouSelection}
        alt=""
        style={{ width: '50%', maxWidth: 'none' }}
      />
    </div>

    <br />
    <br />

    <h5 className="text-center">
      Creation of products integrated with the Open edX LMS site
    </h5>
    <br />
    <div className="d-flex align-items-center">
      <div style={{ width: '50%' }}>
        <div>
          <img
            src={WoocommerceProductNew}
            alt=""
            style={{ width: '100%', maxWidth: 'none' }}
          />
        </div>
        <br />
        <br />
        <div>
          <img
            src={WoocommerceCustomProductAttribute}
            alt=""
            style={{ width: '100%', maxWidth: 'none' }}
          />
        </div>
      </div>
      <div style={{ width: '50%', paddingLeft: '20px' }}>
        <p>
          Once the previous steps are complete, it&apos;s time to start creating
          products in WooCommerce to sell the Open edX courses.
        </p>
        <p>
          <b>Note:</b>
          {' '}
          The purchase of the course usually implies that users
          will get certificates Therefore, it&apos;s necessary to have this
          option configured for the Open edX course. Find more information about
          setting the certificates in the following
          <span>Article</span>
          . This step is crucial to configure the product so
          that the enrollment will be automatically made after the payment.
        </p>
        <p>Go to Products- Add new, and configure the following options</p>
        <p>
          <b>Product name:</b>
          {' '}
          How your product will appear on the page. This
          will create an endpoint with /productname where users will find it.
        </p>
        <p>
          <b>Product main description:</b>
          {' '}
          You will find an editor where you can
          create the description for your product
        </p>
        <p>
          <b>Product data:</b>
          {' '}
          Select simple product-
          <b>Virtual General:</b>
          {' '}
          Establish the price and taxes.
        </p>
        <p>
          <b>Inventory: Define:</b>
          {' '}
          - The SKU - Stock status - Check sold
          individually
        </p>
        <p>
          <b>Note:</b>
          {' '}
          These are the minimum configurations. You can create the
          product according to your needs.
        </p>
        <br />
        <p>
          <b>Attributes:</b>
          <br />
          Add two attributes
        </p>
        <p>
          <b>Attribute 1.</b>
          <br />
          Name: course_id
          <br />
          Value (s): Enter the course ID of the course you want to sell. E.g.
          course-v1:demo-site+certs+en
        </p>
        <p>
          <b>Attribute 2.</b>
          <br />
          Name: course_mode
          <br />
          Value (s): Enter honor
        </p>
        <p>
          <b>Note:</b>
          {' '}
          Don&apos;t check the option Visible on the product page
          so that this information will not be visible for users.
        </p>
      </div>
    </div>
  </>
);

export default {
  integrations_website_cms_simple: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced: GOTO_MANAGE_SETTINGS_INTEGRATIONS_WEBSITE_CMS_SIMPLE,
      nimbus_advanced: GOTO_MANAGE_SETTINGS_INTEGRATIONS_WEBSITE_CMS_SIMPLE,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_INTEGRATIONS_WEBSITE_CMS_SIMPLE,
      edgon: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_se: GOTO_MANAGE_SETTINGS_INTEGRATIONS_WEBSITE_CMS_SIMPLE,
      stratus: GOTO_MANAGE_SETTINGS_INTEGRATIONS_WEBSITE_CMS_SIMPLE,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        Improve the visitor&apos;s experience quickly by
        <b className={Styles.onboardingTitleBlue}>
          integrating your existing landing page
        </b>
        {' '}
        with the LMS site
      </>
    ),
    onboardingSubtitle: (
      <>
        The landing page can be an existing website or
        <br />
        any Content Management System
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            Replace the open edX homepage with your own existing website or
            landing page.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Dynamic content such as the course catalog is still managed by Open
            edX.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            After learners log out from Open edX they are sent back to your
            landing page.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: cmsSimple,
  },
  integrations_website_wordpress: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced: NOT_AVAILABLE.nimbus_enhanced,
      nimbus_advanced: GOTO_MANAGE_SETTINGS_INTEGRATIONS_WEBSITE_WORDPRESS,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_INTEGRATIONS_WEBSITE_WORDPRESS,
      edgon: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_se: GOTO_MANAGE_SETTINGS_INTEGRATIONS_WEBSITE_WORDPRESS,
      stratus: GOTO_MANAGE_SETTINGS_INTEGRATIONS_WEBSITE_WORDPRESS,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        <b className={Styles.onboardingTitleBlue}>
          Take your initiative to a pro level
        </b>
        {' '}
        by integrating Wordpress and Open edX
      </>
    ),
    onboardingSubtitle: (
      <>
        Leverage the power of Wordpress,
        <br />
        the #1 CMS in the world
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            Control the visual aspect and the content of your homepage in great
            detail.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Leverage the visual designs, templates and page builders available
            with Wordpress.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Maintain the consistency across the 2 platforms with the edunext
            exclusive Open edX - Wordpress integration plugin.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: wordpress,
  },
  integrations_third_party_auth_google: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced:
        GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_GOOGLE,
      nimbus_advanced:
        GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_GOOGLE,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_GOOGLE,
      edgon: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_se: GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_GOOGLE,
      stratus: GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_GOOGLE,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        Give your users the convenience of identifying themselves only once and
        <b className={Styles.onboardingTitleBlue}>
          keeping the session valid with Google.
        </b>
      </>
    ),
    onboardingSubtitle: (
      <>
        Avoid the hassle and expense of
        <br />
        managing login credentials.
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            Make it easy for users to authenticate when entering your site with
            Google SSO.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Follow the steps indicated in the settings and make the SSO request
            with Google.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            After your SSO integration has been successfully configured, you can
            turn it on or off in the Settings SSO section.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: tpaGoogle,
  },
  integrations_third_party_auth_facebook: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced:
        GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_FACEBOOK,
      nimbus_advanced:
        GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_FACEBOOK,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_FACEBOOK,
      edgon: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_se: GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_FACEBOOK,
      stratus: GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_FACEBOOK,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        Your users will be able to login to your LMS site using their
        <b className={Styles.onboardingTitleBlue}>Facebook credentials</b>
        {' '}
        in
        Facebook-based SSO.
      </>
    ),
    onboardingSubtitle: (
      <>
        Provides ease and convenience with
        <br />
        Facebook SSO
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            Allow existing Facebook users to easily and effortlessly log in to
            your site.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Follow the steps indicated in the settings and make the SSO request
            with Facebook.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            After your SSO integration has been successfully configured, you can
            turn it on or off in the Settings SSO section.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: tpaFacebook,
  },
  integrations_third_party_auth_linkedin: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced:
        GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_LINKEDIN,
      nimbus_advanced:
        GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_LINKEDIN,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_LINKEDIN,
      edgon: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_se: GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_LINKEDIN,
      stratus: GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_LINKEDIN,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        <b className={Styles.onboardingTitleBlue}>
          Use the number one social network for professionals
        </b>
        {' '}
        in the world and make it easy for your learners to enter your site
      </>
    ),
    onboardingSubtitle: (
      <>
        Connect to Linkedin and facilitate
        <br />
        registrations and logins.
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            SSO ensures that you are ready to implement secure access to your
            site using LinkedIn within minutes.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Follow the steps indicated in the settings and make the SSO request
            with Linkedin.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            After your SSO integration has been successfully configured, you can
            turn it on or off in the Settings SSO section.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: tpaLinkedin,
  },
  integrations_third_party_auth_microsoft: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced:
        GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_MICROSOFT,
      nimbus_advanced:
        GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_MICROSOFT,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_MICROSOFT,
      edgon: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_se: GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_MICROSOFT,
      stratus: GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_MICROSOFT,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        <b className={Styles.onboardingTitleBlue}>
          Add Microsoft SSO to your login and registration
        </b>
        {' '}
        screen securely
      </>
    ),
    onboardingSubtitle: (
      <>
        Fast logins and registrations with just a
        <br />
        couple of clicks.
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            If you think your site may have many Windows Live or Microsoft 365
            users, you may want to offer them the opportunity to sign in with
            their existing credentials.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Follow the steps indicated in the settings and make the SSO request
            with Microsoft.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            After your SSO integration has been successfully configured, you can
            turn it on or off in the Settings SSO section.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: tpaMicrosoft,
  },
  integrations_third_party_auth_saml: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced: NOT_AVAILABLE.nimbus_enhanced,
      nimbus_advanced: GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_SAML,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_SAML,
      edgon: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_se: GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_SAML,
      stratus: GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_SAML,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        <b className={Styles.onboardingTitleBlue}>
          SAML allows the exchange of information,
        </b>
        {' '}
        both authentication and authorization, between different parties
      </>
    ),
    onboardingSubtitle: (
      <>
        Apply Security Assertion Markup
        <br />
        Language on your site.
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            SAML verifies the user&apos;s identity and credentials (password,
            two-factor authentication, etc.).
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Follow the steps indicated in the settings and make the SSO request
            with SAML.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            After your SSO integration has been successfully configured, you can
            turn it on or off in the Settings SSO section.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: tpaSaml,
  },
  integrations_third_party_auth_oauth: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced: NOT_AVAILABLE.nimbus_enhanced,
      nimbus_advanced: GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_OAUTH,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_OAUTH,
      edgon: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_se: GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_OAUTH,
      stratus: GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_OAUTH,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        2.0 OAUTH Allows users to interact with multiple applications
        <b className={Styles.onboardingTitleBlue}>
          without the need to store sensitive credentials.
        </b>
      </>
    ),
    onboardingSubtitle: (
      <>
        OAuth 2.0 is faster and easier to
        <br />
        implement.
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            OAuth 2.0 is an open standard for API authorization, which allows us
            to share information between sites without having to share identity.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Follow the steps indicated in the settings and make the SSO request
            with 2.0 OAUTH.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            After your SSO integration has been successfully configured, you can
            turn it on or off in the Settings SSO section.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: cmsSimple,
  },
  integrations_third_party_auth_settings: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced:
        GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_SETTINGS,
      nimbus_advanced:
        GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_SETTINGS,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_SETTINGS,
      edgon: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_se: GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_SETTINGS,
      stratus: GOTO_MANAGE_SETTINGS_INTEGRATIONS_THIRD_PARTY_AUTH_SETTINGS,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        Your SSO integration ready!
        <b className={Styles.onboardingTitleBlue}>
          Now is the time to take advantage of this powerful integration
        </b>
      </>
    ),
    onboardingSubtitle: (
      <>
        Optimize your users&apos; time and protect
        <br />
        their credentials
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            In this section you can see how many and which external providers
            are using for your SSO.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>Turn your SSO on or off when needed with just one click.</p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Everything is ready to improve the experience of your users when
            registering or logging into your site.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: noPicture,
  },
  integrations_ecommerce_woocommerce: {
    static: (
      <>
        <h5>WooCommerce Configuration</h5>
        <p>
          Integration requisites.
          <br />
          It is required to have your LMS site in a domain of your own.
          <br />
          Active WordPress + LMS integration.
          <br />
          EOX APIs add-on enabled.
          <br />
        </p>
      </>
    ),
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced: NOT_AVAILABLE.nimbus_enhanced,
      nimbus_advanced: GOTO_MANAGE_SETTINGS_WOOCOMMERCE,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      stratus: GOTO_MANAGE_SETTINGS_WOOCOMMERCE,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_WOOCOMMERCE,
      edgon: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_se: GOTO_MANAGE_SETTINGS_WOOCOMMERCE,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        <b className={Styles.onboardingTitleBlue}>
          Take advantage of the powerful
          {' '}
        </b>
        {' '}
        e-commerce tool for sites integrated with wordpress
      </>
    ),
    onboardingSubtitle: (
      <>
        Learn what you can do with
        <br />
        Woocommerce
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            With WooCommerce you can include other payment processors, the
            ability to invoice, among others.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Sell bundles of courses in a single transaction with discounted
            bundle prices.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Handle a wider range of payment processors and notify your customer
            by email after the purchase.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: ecommerceWoocommerce,
    onboardingVideoId: 'zFZOKAXuJIc',
  },
  /**
   * This variable content is configured in the view itself due to its complexity.
   * The `null` property in each of the tiers indicates that the content
   * configured in the view should be displayed.
   */
  integrations_ecommerce_simple: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced: null,
      nimbus_advanced: null,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      stratus: null,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: null,
      edgon: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_se: null,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        This e-commerce solution helps you
        {' '}
        <b className={Styles.onboardingTitleBlue}>
          add payment settings through Paypal
        </b>
      </>
    ),
    onboardingSubtitle: (
      <>
        Giving you the flexibility to monetize your
        <br />
        courses individually
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>Monetize with the security and flexibility provided by PayPal.</p>
        </OnboardingBox>
        <OnboardingBox>
          <p>We give you the step by step to make it a success.</p>
        </OnboardingBox>
        <OnboardingBox>
          <p>Configure the costs and coupons of your courses.</p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: simpleEcommerce,
    onboardingVideoId: 'ywD2T-u-qXM',
  },
  integrations_mobile_apps_unica: {
    static: (
      <p>
        The open edX platform also provides the source code for building and
        deploying native mobile apps for iOS and android for the learner
        experience.
      </p>
    ),
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced: NOT_AVAILABLE.nimbus_enhanced,
      nimbus_advanced: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_se: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      stratus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        <b className={Styles.onboardingTitleBlue}>
          Your initiative more accessible
        </b>
        {' '}
        with mobile apps integration
      </>
    ),
    onboardingSubtitle: (
      <>
        Offer your users a comfortable and
        <br />
        simple way to learn
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            Setting up and deploying the Open edX mobile app requires a lot of
            technical work and effort. For that reason it is important to follow
            the instructions of the technical team once you contact them.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: mobileApp,
  },
  integrations_tacking_tools_ga: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced: GOTO_MANAGE_SETTINGS_TRACKING_TOOLS_GA,
      nimbus_advanced: GOTO_MANAGE_SETTINGS_TRACKING_TOOLS_GA,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: GOTO_MANAGE_SETTINGS_TRACKING_TOOLS_GA,
      edgon: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_se: GOTO_MANAGE_SETTINGS_TRACKING_TOOLS_GA,
      stratus: GOTO_MANAGE_SETTINGS_TRACKING_TOOLS_GA,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        <b className={Styles.onboardingTitleBlue}>
          Analyze the traffic on your LMS
        </b>
        {' '}
        site with Google Analytics
      </>
    ),
    onboardingSubtitle: <div />,
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            Use the power of Google to track and analyze the activity of
            learners in your site.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            As simple as setting up your GA account and provide the property
            code in this page.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            When using Google analytics, be aware of the applicable data privacy
            regulations for your initiative as this tool uses cookies to track
            user behavior.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: googleAnalytics2,
  },
};
