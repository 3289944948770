/**
 * Mapping between the subscription returned by the API (key-depth)
 * and the keys configured in the variable constants.jsx (values) file.
 * Structure sketch:
 * basic:
 * {
 *    suscription.status: key_in_constants,
 * }
 *
 * or complex:
 * {
 *    suscription.status: {
 *      suscription.product_type: {
 *        suscription.product_tier: key_in_constants,
 *      },
 *    },
 * }
 */
export default {
  active: {
    SSS: {
      LT: 'nimbus_free',
      PM: 'nimbus_basic',
      EN: 'nimbus_enhanced',
      PF: 'nimbus_advanced',
    },
    NSS: {
      FR: 'nimbus_free',
      BS: 'nimbus_basic',
      EN: 'nimbus_enhanced',
      AD: 'nimbus_advanced',
    },
    CIR: {
      NA: 'cirrus',
      SE: 'cirrus_se',
      SN: 'cirrus_sn',
    },
    EDG: {
      NA: 'edgon',
      SE: 'edgon_se',
      SN: 'edgon_sn',
    },
    ONP: 'on_premise',
    CON: 'cirrus',
    STR: 'stratus',
    else: 'else',
  },
  inactive: 'inactive_subscription',
};
