/**
 *  PLATFORM CONSTANTS CONTROL CENTER
 *  In this file the platform constants of the project are configured.
 *  In this file we will find ONLY the keys used in the Platform view.
 *
 *  WARNINGS:
 *            - When you need to include more than one HTML tag, they must be contained in
 *              a parent HTML tag, for example:
 *              Examples of incorrect code:
 *              (
 *                <h1>Hello World!</h1>
 *                <p>Lorem ipsum ... </p>
 *              )
 *
 *              Examples of correct code:
 *              (
 *                <>
 *                    <h1>Hello World!</h1>
 *                    <p>Lorem ipsum ... </p>
 *                </>
 *              )
 *
 *            - Make sure to preserve both the structure and the indentation, our format is:
 *              {
 *                section_name: {
 *                    static: (... Text|HTML),
 *                    variable: {
 *                        variable_1: (... Text|HTML),
 *                        ...
 *                    },
 *                },
 *                ...
 *              }
 */
import React from 'react';
import OnboardingBox from '../components/Onboarding/OnboardingBox/OnboardingBox';
import UpgradeSubscriptionButton from '../components/upgradeSubscriptionButton/UpgradeSubscriptionButton';

import myTeamImage from '../assets/onboardings/myTeamOnboarding.png';

import Styles from '../Common.module.scss';

import {
  EMC_USER_FORM,
  SPECIAL_STUDIO_PERMISSIONS_FORM,
  MULTI_SITES_FORM,
  MULTI_ORG_FORM,
} from './modals.constants';
import { NOT_AVAILABLE } from './messages.constants';
import {
  INACTIVE_SUBSCRIPTION,
  INACTIVE_STRATUS_SUBSCRIPTION,
  MISCONFIGURED_SUBSCRIPTION,
} from './subscription.constants';

const INSTANCE_ATTRIBUTES = (
  <p>
    Below is the list of special attributes that are available to your platform.
  </p>
);

const PLATFORM_CUSTOMIZATIONS = (
  <p>
    Below is the list of customizations that have been engaged for your Open edX
    platform at the instance level. Contact edunext sales team to request a
    quotation for an additional customization or contact the edunext support
    team to have any of the customizations removed.
  </p>
);

const MAIN_TEXT_REQUEST_NEW_SITE = (
  <>
    <p>
      Make sure that the domain for your new site is configured at the DNS level
      to point towards edunext servers. You will find detailed explanations for
      these configurations
      <span> </span>
      <a
        className={Styles.link}
        href="https://www.edunext.co/host-open-edx-in-your-own-domain/"
        target="_blank"
        rel="noreferrer"
      >
        here
      </a>
      .
    </p>
    {MULTI_SITES_FORM}
  </>
);

const MAIN_TEXT_REQUEST_NEW_ORG = (
  <>
    <p>
      The edunext support team will receive your request and perform the
      configurations to create the new ORG and link it to your LMS site.
    </p>
    {MULTI_ORG_FORM}
  </>
);

export default {
  my_teams_course_creators: {
    static: (
      <>
        <p>
          Any of the course admins, can grant permissions for another user to be
          an staff or admin in that course from STUDIO No additional permissions
          are required.
        </p>
        <p>
          This section allows you to request the additon or removal of special
          permissions to one of your users in STUDIO, including:
        </p>
        <ul>
          <li>
            <strong>Course creation</strong>
            {' '}
            permissions for a particular ORG
          </li>
          <li>
            Admin permissions for
            {' '}
            <strong>all courses</strong>
            {' '}
            in a particular
            ORG
          </li>
          <li>
            <strong>Library creation</strong>
            {' '}
            permissions
          </li>
        </ul>
      </>
    ),
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: (
        <>
          <p>Your FREE subscription is limited to 1 Course creator.</p>
          <p>
            <UpgradeSubscriptionButton />
          </p>
        </>
      ),
      nimbus_basic: (
        <>
          <p>
            Your PREMIUM subscription is limited to 1 user with Course creator
            permissions. This user is the one that created the subscription
          </p>
          <p>
            <UpgradeSubscriptionButton>
              Improve my subscription
            </UpgradeSubscriptionButton>
          </p>
        </>
      ),
      nimbus_enhanced: (
        <>
          <p>Your ENTERPRISE subscription is limited to 5 Course creators.</p>
          <br />
          <p>
            Before making this request, make sure that the user has already been
            created and activated in the LMS side.
          </p>
          <p>{SPECIAL_STUDIO_PERMISSIONS_FORM}</p>
        </>
      ),
      nimbus_advanced: (
        <>
          <p>
            Before making this request, make sure that the user has already been
            created and activated in the LMS side.
          </p>
          <p>{SPECIAL_STUDIO_PERMISSIONS_FORM}</p>
        </>
      ),
      on_premise: (
        <>
          <p>
            You can manage course creators autonomously from the STUDIO Django
            sysadmin console for your platorm.
          </p>
          <p>
            Alternativey, you can request the edunext support team to grant
            course creation permissions for you to a particular user. Before
            making this request, make sure that the user has already been
            created and activated in the LMS side.
          </p>
          <p>{SPECIAL_STUDIO_PERMISSIONS_FORM}</p>
        </>
      ),
      cirrus: (
        <>
          <p>
            You can manage course creators autonomously from the STUDIO Django
            sysadmin console for your platorm.
          </p>
          <p>
            Alternativey, you can request the edunext support team to grant
            course creation permissions for you to a particular user. Before
            making this request, make sure that the user has already been
            created and activated in the LMS side.
          </p>
          <p>{SPECIAL_STUDIO_PERMISSIONS_FORM}</p>
        </>
      ),
      cirrus_sn: (
        <>
          <p>
            You can manage course creators autonomously from the STUDIO Django
            sysadmin console for your platorm.
          </p>
          <p>
            Alternativey, you can request the edunext support team to grant
            course creation permissions for you to a particular user. Before
            making this request, make sure that the user has already been
            created and activated in the LMS side.
          </p>
          <p>{SPECIAL_STUDIO_PERMISSIONS_FORM}</p>
        </>
      ),
      cirrus_se: (
        <>
          <p>
            Before making this request, make sure that the user has already been
            created and activated in the LMS side.
          </p>
          <p>{SPECIAL_STUDIO_PERMISSIONS_FORM}</p>
        </>
      ),
      edgon: (
        <>
          <p>
            You can manage course creators autonomously from the STUDIO Django
            sysadmin console for your platorm.
          </p>
          <p>
            Alternativey, you can request the edunext support team to grant
            course creation permissions for you to a particular user. Before
            making this request, make sure that the user has already been
            created and activated in the LMS side.
          </p>
          <p>{SPECIAL_STUDIO_PERMISSIONS_FORM}</p>
        </>
      ),
      edgon_sn: (
        <>
          <p>
            You can manage course creators autonomously from the STUDIO Django
            sysadmin console for your platorm.
          </p>
          <p>
            Alternativey, you can request the edunext support team to grant
            course creation permissions for you to a particular user. Before
            making this request, make sure that the user has already been
            created and activated in the LMS side.
          </p>
          <p>{SPECIAL_STUDIO_PERMISSIONS_FORM}</p>
        </>
      ),
      edgon_se: (
        <>
          <p>
            Before making this request, make sure that the user has already been
            created and activated in the LMS side.
          </p>
          <p>{SPECIAL_STUDIO_PERMISSIONS_FORM}</p>
        </>
      ),
      stratus: (
        <>
          <p>
            Before making this request, make sure that the user has already been
            created and activated in the LMS side.
          </p>
          {SPECIAL_STUDIO_PERMISSIONS_FORM}
        </>
      ),
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        This section manages the members of your team that will have
        <b className={Styles.onboardingTitleBlue}>control of your platform</b>
      </>
    ),
    onboardingSubtitle: (
      <>
        Have the right people on your team managing your subscription, platform,
        and sites
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p className="m-0">
            Your team admins will be able to access all settings in Control
            Center.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p className="m-0">
            Form a solid team that successfully manages your initiative.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p className="m-0">
            Add or remove permissions if you have a new member or if there is
            one that is no longer.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: myTeamImage,
  },
  my_teams_site_admins: {
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: (
        <>
          <p>
            Your FREE subscription subscription is limited to 1 admin user in
            the edunext Control Center.
          </p>
          <p>
            <UpgradeSubscriptionButton />
          </p>
        </>
      ),
      nimbus_basic: (
        <>
          <p>
            Your PREMIUM subscription is limited to 1 admin user in the edunext
            Control Center.
          </p>
          <p>
            <UpgradeSubscriptionButton>
              Improve my subscription
            </UpgradeSubscriptionButton>
          </p>
        </>
      ),
      nimbus_enhanced: (
        <>
          <p>
            Your ENTERPRISE subscription is limited to 5 admin users in the
            edunext Control Center.
          </p>
          <p>
            <UpgradeSubscriptionButton>
              Improve my subscription
            </UpgradeSubscriptionButton>
          </p>
          <p className="d-flex align-items-center">{EMC_USER_FORM}</p>
        </>
      ),
      nimbus_advanced: (
        <p className="d-flex align-items-center">{EMC_USER_FORM}</p>
      ),
      cirrus: <p className="d-flex align-items-center">{EMC_USER_FORM}</p>,
      cirrus_sn: <p className="d-flex align-items-center">{EMC_USER_FORM}</p>,
      cirrus_se: <p className="d-flex align-items-center">{EMC_USER_FORM}</p>,
      edgon: <p className="d-flex align-items-center">{EMC_USER_FORM}</p>,
      edgon_sn: <p className="d-flex align-items-center">{EMC_USER_FORM}</p>,
      edgon_se: <p className="d-flex align-items-center">{EMC_USER_FORM}</p>,
      on_premise: <p className="d-flex align-items-center">{EMC_USER_FORM}</p>,
      stratus: (
        <>
          <p>
            <UpgradeSubscriptionButton>
              Improve my subscription
            </UpgradeSubscriptionButton>
          </p>
          <p className="d-flex align-items-center">{EMC_USER_FORM}</p>
        </>
      ),
      else: MISCONFIGURED_SUBSCRIPTION,
    },
  },
  platform_instance_unica: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: INSTANCE_ATTRIBUTES,
      nimbus_basic: INSTANCE_ATTRIBUTES,
      nimbus_enhanced: INSTANCE_ATTRIBUTES,
      nimbus_advanced: INSTANCE_ATTRIBUTES,
      on_premise: INSTANCE_ATTRIBUTES,
      cirrus: INSTANCE_ATTRIBUTES,
      cirrus_sn: INSTANCE_ATTRIBUTES,
      cirrus_se: INSTANCE_ATTRIBUTES,
      edgon: INSTANCE_ATTRIBUTES,
      edgon_sn: INSTANCE_ATTRIBUTES,
      edgon_se: INSTANCE_ATTRIBUTES,
      stratus: INSTANCE_ATTRIBUTES,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
  },
  platform_customizations_unica: {
    static: (
      <p>
        Customizations are functionalities that are built for a specific Open
        edX instance at the platform level to support a use case or need that is
        not well covered by the standar codebase.
      </p>
    ),
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: PLATFORM_CUSTOMIZATIONS,
      nimbus_enhanced: PLATFORM_CUSTOMIZATIONS,
      nimbus_advanced: PLATFORM_CUSTOMIZATIONS,
      on_premise: PLATFORM_CUSTOMIZATIONS,
      cirrus: PLATFORM_CUSTOMIZATIONS,
      cirrus_sn: PLATFORM_CUSTOMIZATIONS,
      cirrus_se: PLATFORM_CUSTOMIZATIONS,
      edgon: PLATFORM_CUSTOMIZATIONS,
      edgon_sn: PLATFORM_CUSTOMIZATIONS,
      edgon_se: PLATFORM_CUSTOMIZATIONS,
      stratus: PLATFORM_CUSTOMIZATIONS,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
  },
  sites_unica: {
    static: (
      <>
        <p>
          This section displays the LMS site o sites available in your platform.
        </p>
        <p>
          Some subscriptions will only have one site, but having multiple sites
          enables more advance use cases such as:
        </p>
        <ul>
          <li>
            Having production and sandbox or test sites running independently.
          </li>
          <li>
            Having a public site, open to everyone, and an internal site
            restricted for a specific audience.
          </li>
          <li>Running Multiple online learning initiatives independently.</li>
          <li>
            Having a configuration where there is a Master site with all the
            courses and multiple sub-sites with only the courses of certain
            organizations.
          </li>
        </ul>
        <p>
          In the multi sites configuration, each LMS site or tenant will be
          configured independently with its own domain, branding, content and
          organization, which allows the site to display only a specific subset
          of courses. All the sites will be managed and billed under the same
          platform and subscription.
        </p>
      </>
    ),
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced: (
        <>
          <p>
            Your ENTERPRISE subscription allows you to have 2 sites, one for
            testing and one for production.
          </p>
          <p>
            <b>
              Use the button below to request and perform the configurations to
              create and launch a new LMS site
            </b>
          </p>
          {MAIN_TEXT_REQUEST_NEW_SITE}
        </>
      ),
      nimbus_advanced: (
        <>
          <p>
            Your PERFORMANCE subscription allows you to have up to 10 sites,
            including those for testing for production.
          </p>
          <p>
            <b>
              Use the button below to request and perform the configurations to
              create and launch a new LMS site
            </b>
          </p>
          {MAIN_TEXT_REQUEST_NEW_SITE}
        </>
      ),
      on_premise: (
        <>
          <p>
            If your subscription has support for multi sites, Use the button
            below to request and perform the configurations to create and launch
            a new LMS site
          </p>
          {MAIN_TEXT_REQUEST_NEW_SITE}
        </>
      ),
      cirrus: (
        <>
          <p>
            If your subscription has support for multi sites, Use the button
            below to request and perform the configurations to create and launch
            a new LMS site
          </p>
          {MAIN_TEXT_REQUEST_NEW_SITE}
        </>
      ),
      cirrus_sn: (
        <>
          <p>
            If your subscription has support for multi sites, Use the button
            below to request and perform the configurations to create and launch
            a new LMS site
          </p>
          {MAIN_TEXT_REQUEST_NEW_SITE}
        </>
      ),
      cirrus_se: (
        <>
          <p>
            Your PERFORMANCE subscription allows you to have up to 10 sites,
            including those for testing for production.
          </p>
          <p>
            <b>
              Use the button below to request and perform the configurations to
              create and launch a new LMS site
            </b>
          </p>
          {MAIN_TEXT_REQUEST_NEW_SITE}
        </>
      ),
      edgon: (
        <>
          <p>
            If your subscription has support for multi sites, Use the button
            below to request and perform the configurations to create and launch
            a new LMS site
          </p>
          {MAIN_TEXT_REQUEST_NEW_SITE}
        </>
      ),
      edgon_sn: (
        <>
          <p>
            If your subscription has support for multi sites, Use the button
            below to request and perform the configurations to create and launch
            a new LMS site
          </p>
          {MAIN_TEXT_REQUEST_NEW_SITE}
        </>
      ),
      edgon_se: (
        <>
          <p>
            Your PERFORMANCE subscription allows you to have up to 10 sites,
            including those for testing for production.
          </p>
          <p>
            <b>
              Use the button below to request and perform the configurations to
              create and launch a new LMS site
            </b>
          </p>
          {MAIN_TEXT_REQUEST_NEW_SITE}
        </>
      ),
      stratus: (
        <>
          <p>
            Your STRATUS subscription allows to set up multiple sites for
            testing and for production.
          </p>
          <p>
            <b>
              Use the button below to request and perform the configurations to
              create and launch a new LMS site
            </b>
          </p>
          {MAIN_TEXT_REQUEST_NEW_SITE}
        </>
      ),
      else: MISCONFIGURED_SUBSCRIPTION,
    },
  },
  orgs_unique: {
    static: (
      <>
        <p>
          An organization is a key record that is linked to an LMS site and
          defines what courses appear on that site. Courses created in
          organization &quot;X&quot; will appear on the LMS site that has the
          organization X associated with it. Users with permission to create
          courses with certain organizations(ORGs) will see a list (or only one
          org) to select, when creating a course in Studio. The organization
          will help build the course ID, a unique identifier for an Open edX
          course.
        </p>
        <p>
          While many platforms will only have One organization, there are some
          advantages of having multiple organizations in order to better to
          organize your course catalog. It&apos;s also possible to configure a
          different certificate template for each organization.
        </p>
      </>
    ),
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced: (
        <>
          <p>Your ENTERPRISE subscription is limited to 5 Organizations.</p>
          <p>
            <b>
              Use the button below to request the creation of a new organization
              for your LMS site.
            </b>
          </p>
          {MAIN_TEXT_REQUEST_NEW_ORG}
        </>
      ),
      nimbus_advanced: (
        <>
          <p>
            <b>
              Use the button below to request the creation of a new organization
              for your LMS site.
            </b>
          </p>
          {MAIN_TEXT_REQUEST_NEW_ORG}
        </>
      ),
      on_premise: (
        <>
          <p>
            You can manage organizations autonomously from the Django sysadmin
            console for your platorm.
          </p>
          <p>
            <b>
              Alternatively, you can request the creation of an organization to
              the edunext support team. Use the button below to request the
              creation of a new organization for your LMS site.
            </b>
          </p>
          {MAIN_TEXT_REQUEST_NEW_ORG}
        </>
      ),
      cirrus: (
        <>
          <p>
            You can manage organizations autonomously from the Django sysadmin
            console for your platorm.
          </p>
          <p>
            <b>
              Alternatively, you can request the creation of an organization to
              the edunext support team. Use the button below to request the
              creation of a new organization for your LMS site.
            </b>
          </p>
          {MAIN_TEXT_REQUEST_NEW_ORG}
        </>
      ),
      cirrus_sn: (
        <>
          <p>
            You can manage organizations autonomously from the Django sysadmin
            console for your platorm.
          </p>
          <p>
            <b>
              Alternatively, you can request the creation of an organization to
              the edunext support team. Use the button below to request the
              creation of a new organization for your LMS site.
            </b>
          </p>
          {MAIN_TEXT_REQUEST_NEW_ORG}
        </>
      ),
      cirrus_se: (
        <>
          <p>
            <b>
              Use the button below to request the creation of a new organization
              for your LMS site.
            </b>
          </p>
          {MAIN_TEXT_REQUEST_NEW_ORG}
        </>
      ),
      edgon: (
        <>
          <p>
            You can manage organizations autonomously from the Django sysadmin
            console for your platorm.
          </p>
          <p>
            <b>
              Alternatively, you can request the creation of an organization to
              the edunext support team. Use the button below to request the
              creation of a new organization for your LMS site.
            </b>
          </p>
          {MAIN_TEXT_REQUEST_NEW_ORG}
        </>
      ),
      edgon_sn: (
        <>
          <p>
            You can manage organizations autonomously from the Django sysadmin
            console for your platorm.
          </p>
          <p>
            <b>
              Alternatively, you can request the creation of an organization to
              the edunext support team. Use the button below to request the
              creation of a new organization for your LMS site.
            </b>
          </p>
          {MAIN_TEXT_REQUEST_NEW_ORG}
        </>
      ),
      edgon_se: (
        <>
          <p>
            <b>
              Use the button below to request the creation of a new organization
              for your LMS site.
            </b>
          </p>
          {MAIN_TEXT_REQUEST_NEW_ORG}
        </>
      ),
      stratus: (
        <>
          <p>
            <b>
              Use the button below to request the creation of a new organization
              for your LMS site.
            </b>
          </p>
          {MAIN_TEXT_REQUEST_NEW_ORG}
        </>
      ),
      else: MISCONFIGURED_SUBSCRIPTION,
    },
  },
};
