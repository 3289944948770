/**
 *  SITE DEVELOPER CONSTANTS CONTROL CENTER
 *  In this file the site developer constants of the project are configured.
 *  In this file we will find ONLY the keys used in the My Site > Site Developer view.
 *
 *  WARNINGS:
 *            - When you need to include more than one HTML tag, they must be contained in
 *              a parent HTML tag, for example:
 *              Examples of incorrect code:
 *              (
 *                <h1>Hello World!</h1>
 *                <p>Lorem ipsum ... </p>
 *              )
 *
 *              Examples of correct code:
 *              (
 *                <>
 *                    <h1>Hello World!</h1>
 *                    <p>Lorem ipsum ... </p>
 *                </>
 *              )
 *
 *            - Make sure to preserve both the structure and the indentation, our format is:
 *              {
 *                section_name: {
 *                    static: (... Text|HTML),
 *                    variable: {
 *                        variable_1: (... Text|HTML),
 *                        ...
 *                    },
 *                },
 *                ...
 *              }
 */
import React from 'react';

import CustomButton from '../components/customButton/CustomButton';
import DeveloperApiForm from '../components/DeveloperApiForm/DeveloperApiForm';
import OnboardingBox from '../components/Onboarding/OnboardingBox/OnboardingBox';

import siteAttributes from '../assets/onboardings/site-atributes.png';
import apiOpenEdx from '../assets/onboardings/api-open-edx.png';
import eoxApi from '../assets/onboardings/eox-api.png';
import customizations from '../assets/onboardings/customizations.png';
import triggers from '../assets/onboardings/triggers.png';
import eoxCoreImage from '../assets/eox-core.png';
import openEdxApi from '../assets/open-edx-api.png';

import {
  MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
  NOT_AVAILABLE,
} from './messages.constants';
import {
  INACTIVE_SUBSCRIPTION,
  INACTIVE_STRATUS_SUBSCRIPTION,
  MISCONFIGURED_SUBSCRIPTION,
} from './subscription.constants';

const DEVELOPER_API = {
  component: ({ site }) => (
    <>
      <p>
        Below you will find access to the interactive documentation for each of
        the APIs. This resources will allow a developer to understand the
        details, parameters and responses of any of the different API endpoints
        and also experiment by making API request directly. A set of credentials
        will be needed for the authentication in order to make API requests.
        Contact the edunext support team to request the credentials to be
        generated and shared through a secure channel.
      </p>
      <div className="d-flex align-items-center">
        <a
          href={site.lms_base ? `${site.lms_base}/api-docs/` : '#'}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={openEdxApi}
            alt="Open Edx"
            style={{
              minWidth: 300,
              borderRadius: 16,
              boxShadow: '3px 4px 9px rgba(0, 0, 0, 0.16)',
            }}
          />
        </a>
        <div className="mt-2 ms-5">
          <h5>OPEN EDX NATIVE API</h5>
          <p>
            This API is the one used by the different pieces of the platform to
            interact withthe data. As such, all the endpoints are limited by the
            permissions model of the particular user that has an active session
            when making the call. This means that the records that are retrieved
            are the ones that are either public for everyone to see or for the
            specific user. The records that can be modified through these
            endpoints are those that the user can modify through the application
            as well.
          </p>
          <a
            href={site.lms_base ? `${site.lms_base}/api-docs/` : '#'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomButton variant="normalOutLine">
              Click to see the API docs
            </CustomButton>
          </a>
        </div>
      </div>
    </>
  ),
};

const EOX_CORE_API = (
  <>
    <p>
      Below you will find access to the interactive documentation for each of
      the APIs. This resources will allow a developer to understand the
      details, parameters and responses of any of the different API endpoints
      and also experiment by making API request directly. A set of credentials
      will be needed for the authentication in order to make API requests.
      Contact the edunext support team to request the credentials to be
      generated and shared through a secure channel.
    </p>
    <div className="d-flex aling-items-center my-5">
      <img
        src={eoxCoreImage}
        alt="Eox core"
        style={{
          minWidth: 300,
          maxWidth: 300,
          maxHeight: 300,
          borderRadius: 16,
          boxShadow: '3px 4px 9px rgba(0, 0, 0, 0.16)',
        }}
      />
      <div>
        <div className="d-flex flex-column mt-2 ms-5">
          <h5>EOX CORE API</h5>
          <p>
            This is an additional API created by edunext in order to enable
            integrations with external systems that can have management
            capabilities to the site&apos;s records.It requires an initial
            authentication to allow the API calls to be made and then it can
            be used to retrieve or affect any of the available records as it
            would be done by a superadmin.
          </p>
          <div className="d-flex align-items-center">
            <DeveloperApiForm />
          </div>
        </div>
      </div>
    </div>
  </>
);

export default {
  advanced_unica: {
    static: (
      <p>
        This section references the advanced configurations that have been added
        to optimize the functionality or behaviour of your site.
      </p>
    ),
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      nimbus_advanced: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_se: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      stratus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        Advanced configurations added to optimize the functionality or behaviour
        of your site
      </>
    ),
    onboardingSubtitle: (
      <>
        Learn about some of the advanced
        <br />
        {' '}
        settings you can request
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <ul>
            <li>Marketing site integration.</li>
            <li>Ecommerce configurations</li>
            <li>Acces restriction</li>
            <li>Redirections</li>
            <li>I18n</li>
          </ul>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Each advanced configuration needs to be carefully documented and
            maintained over time to ensure continuous operation across new
            versions of the platform.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Talk to the support team In case there is any inconsistency in the
            information presented in this section.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: siteAttributes,
  },
  developer_tools_apis_unica: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: DEVELOPER_API,
      nimbus_enhanced: DEVELOPER_API,
      nimbus_advanced: DEVELOPER_API,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: DEVELOPER_API,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: DEVELOPER_API,
      edgon: DEVELOPER_API,
      edgon_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_se: DEVELOPER_API,
      stratus: DEVELOPER_API,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        Explore the Open edX Native REST APIs for potential applications in your
        initiative
      </>
    ),
    onboardingSubtitle: (
      <>
        Take advantage of the interactive API documentation
        <br />
        {' '}
        in Swagger
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            Leverage the native REST APIs to create integrations between the
            platform and external systems.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            283 endpoints to interact with 36 application models. Enrollment
            API, User API, and Data Analysis API.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Authorization based on username and password to control access to
            the models and records available for a specific user.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: apiOpenEdx,
  },
  developer_tools_apis_eox_core: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced: NOT_AVAILABLE.nimbus_enhanced,
      nimbus_advanced: EOX_CORE_API,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: EOX_CORE_API,
      edgon: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_se: EOX_CORE_API,
      stratus: EOX_CORE_API,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        Leverage edunext&apos;s exclusive management REST APIs to build powerful
        integrations between Open edX and your own applications
      </>
    ),
    onboardingSubtitle: (
      <>
        Take advantage of the interactive API documentation
        <br />
        {' '}
        in Swagger
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            Build automation and special workflows to match your business case
            and operate more efficiently at scale.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            8 administrative API endpoints to interact with users, enrollments
            and grades.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Authorization based on provided client_ID and Client_secret to allow
            secure integrations that can handle the information of any user in
            the platform.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: eoxApi,
  },
  developer_tools_triggers_unica: {
    static: (
      <p>
        This new feature allows for certain events in the platform to raise
        signals that can be connected with external processes or services.
      </p>
    ),
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      nimbus_advanced: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_se: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      stratus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>Leverage The new Open edX events framework to trigger custom actions</>
    ),
    onboardingSubtitle: (
      <>
        A powerful new way of customizing the behaviour of the platform and
        <br />
        {' '}
        build custom integrations.
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <div className="d-flex flex-column">
            <p>Currently available events:</p>
            <ul>
              <li>Post registration</li>
              <li>Post enrollment</li>
              <li>Post unenrollment</li>
              <li>Post certificate generation</li>
            </ul>
          </div>
        </OnboardingBox>
        <OnboardingBox>
          <div className="d-flex flex-column">
            <p>Possible actions:</p>
            <ul>
              <li>Calling an external webhook</li>
              <li>Triggering an enrollment</li>
              <li>Assigning a Grade</li>
            </ul>
          </div>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Talk to the edunext sales team to get help in building a new trigger
            or action to serve your needs.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: triggers,
  },
  developer_tools_customizations_unica: {
    static: (
      <p>
        Customizations are functionalities that are built for a specific site to
        support a use case or need that is not well covered by the open edX
        platform.
      </p>
    ),
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      nimbus_advanced: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_se: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      stratus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: <>Keep track of your site customizations.</>,
    onboardingSubtitle: (
      <>
        The Open edX platform can be flexible to adapt to specific requirements.
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            There are multiple ways of customizing the platform: Theming, custom
            Xblocks, Pluggins, custom actions, taylor made solutions and more.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Each customization needs to be carefully documented and maintained
            over time to ensure continuous operation across new versions of the
            platform.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Talk to the sales team so they can support you with a specific need.
            Together we can make it happen.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: customizations,
  },
  developer_tools_api_docs_buttons: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced: NOT_AVAILABLE.nimbus_enhanced,
      nimbus_advanced: <DeveloperApiForm />,
      on_premise: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      cirrus_se: <DeveloperApiForm />,
      edgon: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_sn: MESSAGE_EMPOWERED_AND_CONTACT_EDUNEXT,
      edgon_se: <DeveloperApiForm />,
      stratus: <DeveloperApiForm />,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
  },
};
