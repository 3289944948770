/**
 *  SITE OPERATION CONSTANTS CONTROL CENTER
 *  In this file the site operation constants of the project are configured.
 *  In this file we will find ONLY the keys used in the My Site > Site Operation view.
 *
 *  WARNINGS:
 *            - When you need to include more than one HTML tag, they must be contained in
 *              a parent HTML tag, for example:
 *              Examples of incorrect code:
 *              (
 *                <h1>Hello World!</h1>
 *                <p>Lorem ipsum ... </p>
 *              )
 *
 *              Examples of correct code:
 *              (
 *                <>
 *                    <h1>Hello World!</h1>
 *                    <p>Lorem ipsum ... </p>
 *                </>
 *              )
 *
 *            - Make sure to preserve both the structure and the indentation, our format is:
 *              {
 *                section_name: {
 *                    static: (... Text|HTML),
 *                    variable: {
 *                        variable_1: (... Text|HTML),
 *                        ...
 *                    },
 *                },
 *                ...
 *              }
 */
import React from 'react';
import { Col, Row } from 'antd';
import { useParams } from 'react-router-dom';

import ZohoForm from '../components/ZohoForm/ZohoForm';
import DynamicElement from '../components/DynamicElement/DynamicElement';
import AttributeWrapper from '../components/AttributeWrapper/AttributeWrapper';
import tabContentFormatter from '../helpers/utils/tabContentFormatter/tabContentFormatter';

import intermediate from '../assets/intermediate.png';

import Styles from '../Common.module.scss';

import { NOT_AVAILABLE } from './messages.constants';

import { GOTO_MANAGE_SETTINGS_COURSEEXPERIENCE } from './iframes.constants';

import {
  INACTIVE_SUBSCRIPTION,
  MISCONFIGURED_SUBSCRIPTION,
  INACTIVE_STRATUS_SUBSCRIPTION,
} from './subscription.constants';

const ADD_NEW_COURSES_TAB_1 = (
  <Row justify="center" align="middle">
    <Col xs={24} md={12}>
      <ol>
        <li>
          Go to
          <DynamicElement type="studio" text="STUDIO" />
          and login.
        </li>
        <br />
        <li>
          In the upper left section click on the
          <b className={Styles.boldText}> New Course</b>
          button.
        </li>
        <br />
        <li>
          Add the
          <b className={Styles.boldText}> Course Name</b>
          select the
          <b className={Styles.boldText}> Organization</b>
          , add the
          <b className={Styles.boldText}> Course Number,</b>
          <b className={Styles.boldText}> Course Run</b>
          and click the
          <b className={Styles.boldText}> Create</b>
          button.
        </li>
        <br />
        <li>
          At this point your course is already created, you just have to start
          adding the content.
        </li>
        <br />
      </ol>
    </Col>
    <Col xs={0} md={12}>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/0Wamx1A2iKE"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </Col>
  </Row>
);

const VIEW_ALL_COURSES_TAB_1 = (
  <Row justify="center" align="middle">
    <Col xs={24} md={12}>
      <ol>
        <li>
          Go to
          <DynamicElement type="studio" text="STUDIO" />
          and login.
        </li>
        <br />
        <li>In STUDIO home all your courses are listed.</li>
        <br />
      </ol>
    </Col>
    <Col xs={0} md={12}>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/qrs_kpJfkng"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </Col>
  </Row>
);

const MODIFY_COURSE_TAB_1 = (
  <Row justify="center" align="middle">
    <Col xs={24} md={12}>
      <ol>
        <li>
          Go to
          <DynamicElement type="studio" text="STUDIO" />
          and login.
        </li>
        <br />
        <li>Select the course you want to modify.</li>
        <br />
        <li>Start to edit the content and configurations of your course.</li>
        <br />
      </ol>
    </Col>
    <Col xs={0} md={12}>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/WynQ6ZpbJac"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </Col>
  </Row>
);

const MODIFY_COURSE_TAB_3 = (
  <Col>
    <Row>
      <p>
        Give your course team permission to hide the discussion tab for each
        course.
      </p>
    </Row>
    {GOTO_MANAGE_SETTINGS_COURSEEXPERIENCE}
  </Col>
);

const DELETE_COURSE_TAB_1 = (
  <Row>
    <DynamicElement
      type="iframeCustom"
      url="https://public.docs.edunext.co/en/latest/external/course_instructors/course_eol/remove_course.html"
    />
  </Row>
);

const DELETE_COURSE_TAB_2 = (
  <Col>
    <Row justify="center" align="middle">
      <Col xl={{ span: 12 }} lg={{ span: 24 }}>
        <div style={{ textAlign: 'center' }}>
          <img
            src={intermediate}
            alt="intermediate"
            style={{ maxWidth: '400px', width: '100%' }}
          />
        </div>
      </Col>
      <Col xl={{ span: 12 }} lg={{ span: 24 }}>
        <div style={{ maxWidth: '600px', margin: '0 auto' }}>
          <p>
            Make sure the information you are providing is correct, as all
            students will be unenrolled and all course content will be deleted
            in your initiative.
          </p>
          <ZohoForm
            subject="Request delete learner"
            showSubject={false}
            showUserName={false}
            showEmail={false}
            showOptions={false}
            showDescription={false}
            showAttachments={false}
            extraFields={[
              {
                id: 'course-id',
                name: 'course-id',
                label: 'Course ID',
                isRequired: true,
              },
              {
                id: 'additional-notes',
                name: 'additional-notes',
                label: 'Additional comments:',
                helpType: 'code',
                textHelp:
                  'When requesting support that removes a course, the content is completely removed and the students are unrolled but they are not removed from the site.',
                type: 'textarea',
                isRequired: false,
              },
            ]}
          />
        </div>
      </Col>
    </Row>
  </Col>
);

const X_BLOCK_TAB_1 = (
  <Row>
    <DynamicElement
      type="iframeCustom"
      url="https://public.docs.edunext.co/en/latest/external/course_creators/authoring_courses/xblock_implementation_guides/intro_to_xblock.html?highlight=Xblocks"
    />
  </Row>
);

const X_BLOCK_TAB_2 = (
  <Row>
    <DynamicElement
      type="iframeCustom"
      url="https://public.docs.edunext.co/en/latest/external/course_creators/authoring_courses/xblock_implementation_guides/index.html"
    />
  </Row>
);

const InnovativeLearnerExperiences = () => {
  const { siteId } = useParams();

  return (
    <AttributeWrapper
      showType
      attributeName="Your X-Blocks"
      customQuery={`?tenant__external_key=${siteId}`}
      category="operations_advanced"
      ownerType="tenant_attributes"
      modelName="tenant"
      attributeType="xblock"
      canMakeRequest={false}
    />
  );
};

const X_BLOCK_TAB_3 = (
  <Col>
    <InnovativeLearnerExperiences />
  </Col>
);

const X_BLOCK_TAB_NOT_AVAILABLE_NIMBUS_FREE = (
  <Col>
    {NOT_AVAILABLE.nimbus_free}
    <InnovativeLearnerExperiences />
  </Col>
);

const PROGRAMS_TAB_1 = (
  <Col>
    <Row justify="center" align="middle">
      <Col xl={{ span: 12 }} lg={{ span: 24 }}>
        <div style={{ textAlign: 'center' }}>
          <img
            src={intermediate}
            alt="intermediate"
            style={{ maxWidth: '400px', width: '100%' }}
          />
        </div>
      </Col>
      <Col xl={{ span: 12 }} lg={{ span: 24 }}>
        <div style={{ maxWidth: '600px', margin: '0 auto' }}>
          <p>The LMS site requires the activation of this functionality.</p>
          <ZohoForm
            subject="Request delete learner"
            submitButtonText="Request LMS Domain activation"
            showSubject={false}
            showUserName={false}
            showEmail={false}
            showOptions={false}
            showDescription={false}
            showAttachments={false}
            extraFields={[
              {
                id: 'url-lms-site',
                name: 'url-lms-site',
                label: 'URL for your LMS site',
                isRequired: true,
                validation: { type: 'url', warningOnly: false },
              },
            ]}
          />
        </div>
      </Col>
    </Row>
  </Col>
);

const PROGRAMS_TAB_2 = (
  <Row>
    <DynamicElement
      type="iframeCustom"
      url="https://public.docs.edunext.co/en/latest/external/course_creators/prepare_test_launch_courses/multi-course-programs.html"
    />
  </Row>
);

export default {
  add_new_course: {
    static: (
      <>
        <p>
          STUDIO platform is the content management system and course authoring
          platform for creating and configuring courses and libraries in Open
          edX.
        </p>
        <p>
          With Studio, you can create and build course content, add and organize
          courseware, add and configure course activities, upload and manage
          course files, establish and configure the course policy, create and
          configure certificates, define and publish the course schedule, and
          more.
        </p>
        <p>
          To enter STUDIO you need to have credentials and permissions to be
          able to create courses, if you still do not have credentials, contact
          the owner of the initiative to give you the necessary permissions.
        </p>
      </>
    ),
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: [
        tabContentFormatter(ADD_NEW_COURSES_TAB_1, 'From STUDIO', 0),
      ],
      nimbus_basic: [
        tabContentFormatter(ADD_NEW_COURSES_TAB_1, 'From STUDIO', 0),
      ],
      nimbus_enhanced: [
        tabContentFormatter(ADD_NEW_COURSES_TAB_1, 'From STUDIO', 0),
      ],
      nimbus_advanced: [
        tabContentFormatter(ADD_NEW_COURSES_TAB_1, 'From STUDIO', 0),
      ],
      on_premise: [
        tabContentFormatter(ADD_NEW_COURSES_TAB_1, 'From STUDIO', 0),
      ],
      cirrus: [tabContentFormatter(ADD_NEW_COURSES_TAB_1, 'From STUDIO', 0)],
      cirrus_sn: [tabContentFormatter(ADD_NEW_COURSES_TAB_1, 'From STUDIO', 0)],
      cirrus_se: [tabContentFormatter(ADD_NEW_COURSES_TAB_1, 'From STUDIO', 0)],
      edgon: [tabContentFormatter(ADD_NEW_COURSES_TAB_1, 'From STUDIO', 0)],
      edgon_sn: [tabContentFormatter(ADD_NEW_COURSES_TAB_1, 'From STUDIO', 0)],
      edgon_se: [tabContentFormatter(ADD_NEW_COURSES_TAB_1, 'From STUDIO', 0)],
      stratus: [tabContentFormatter(ADD_NEW_COURSES_TAB_1, 'From STUDIO', 0)],
      else: MISCONFIGURED_SUBSCRIPTION,
    },
  },
  view_all_courses: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: [
        tabContentFormatter(VIEW_ALL_COURSES_TAB_1, 'From STUDIO', 0),
      ],
      nimbus_basic: [
        tabContentFormatter(VIEW_ALL_COURSES_TAB_1, 'From STUDIO', 0),
      ],
      nimbus_enhanced: [
        tabContentFormatter(VIEW_ALL_COURSES_TAB_1, 'From STUDIO', 0),
      ],
      nimbus_advanced: [
        tabContentFormatter(VIEW_ALL_COURSES_TAB_1, 'From STUDIO', 0),
      ],
      on_premise: [
        tabContentFormatter(VIEW_ALL_COURSES_TAB_1, 'From STUDIO', 0),
      ],
      cirrus: [tabContentFormatter(VIEW_ALL_COURSES_TAB_1, 'From STUDIO', 0)],
      cirrus_sn: [
        tabContentFormatter(VIEW_ALL_COURSES_TAB_1, 'From STUDIO', 0),
      ],
      cirrus_se: [
        tabContentFormatter(VIEW_ALL_COURSES_TAB_1, 'From STUDIO', 0),
      ],
      edgon: [tabContentFormatter(VIEW_ALL_COURSES_TAB_1, 'From STUDIO', 0)],
      edgon_sn: [
        tabContentFormatter(VIEW_ALL_COURSES_TAB_1, 'From STUDIO', 0),
      ],
      edgon_se: [
        tabContentFormatter(VIEW_ALL_COURSES_TAB_1, 'From STUDIO', 0),
      ],
      stratus: [tabContentFormatter(VIEW_ALL_COURSES_TAB_1, 'From STUDIO', 0)],
      else: MISCONFIGURED_SUBSCRIPTION,
    },
  },
  modify_course: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: [
        tabContentFormatter(MODIFY_COURSE_TAB_1, 'Edit from STUDIO', 0),
        tabContentFormatter(NOT_AVAILABLE.nimbus_free, 'Discussion setting', 1),
      ],
      nimbus_basic: [
        tabContentFormatter(MODIFY_COURSE_TAB_1, 'Edit from STUDIO', 0),
        tabContentFormatter(MODIFY_COURSE_TAB_3, 'Discussion setting', 1),
      ],
      nimbus_enhanced: [
        tabContentFormatter(MODIFY_COURSE_TAB_1, 'Edit from STUDIO', 0),
        tabContentFormatter(MODIFY_COURSE_TAB_3, 'Discussion setting', 1),
      ],
      nimbus_advanced: [
        tabContentFormatter(MODIFY_COURSE_TAB_1, 'Edit from STUDIO', 0),
        tabContentFormatter(MODIFY_COURSE_TAB_3, 'Discussion setting', 1),
      ],
      on_premise: [
        tabContentFormatter(MODIFY_COURSE_TAB_1, 'Edit from STUDIO', 0),
        tabContentFormatter(MODIFY_COURSE_TAB_3, 'Discussion setting', 1),
      ],
      cirrus: [
        tabContentFormatter(MODIFY_COURSE_TAB_1, 'Edit from STUDIO', 0),
        tabContentFormatter(MODIFY_COURSE_TAB_3, 'Discussion setting', 1),
      ],
      cirrus_sn: [
        tabContentFormatter(MODIFY_COURSE_TAB_1, 'Edit from STUDIO', 0),
        tabContentFormatter(MODIFY_COURSE_TAB_3, 'Discussion setting', 1),
      ],
      cirrus_se: [
        tabContentFormatter(MODIFY_COURSE_TAB_1, 'Edit from STUDIO', 0),
        tabContentFormatter(MODIFY_COURSE_TAB_3, 'Discussion setting', 1),
      ],
      edgon: [
        tabContentFormatter(MODIFY_COURSE_TAB_1, 'Edit from STUDIO', 0),
        tabContentFormatter(MODIFY_COURSE_TAB_3, 'Discussion setting', 1),
      ],
      edgon_sn: [
        tabContentFormatter(MODIFY_COURSE_TAB_1, 'Edit from STUDIO', 0),
        tabContentFormatter(MODIFY_COURSE_TAB_3, 'Discussion setting', 1),
      ],
      edgon_se: [
        tabContentFormatter(MODIFY_COURSE_TAB_1, 'Edit from STUDIO', 0),
        tabContentFormatter(MODIFY_COURSE_TAB_3, 'Discussion setting', 1),
      ],
      stratus: [
        tabContentFormatter(MODIFY_COURSE_TAB_1, 'Edit from STUDIO', 0),
        tabContentFormatter(MODIFY_COURSE_TAB_3, 'Discussion setting', 1),
      ],
      else: MISCONFIGURED_SUBSCRIPTION,
    },
  },
  delete_course: {
    static: (
      <>
        <p>
          Deleting a course in the Open edX platform is a somewhat complex
          procedure, so in order to delete a course completely, it must be done
          by the edunext support team.
        </p>
        <p>
          However you can &quot;Temporarily Delete&quot; the course by hiding it
          completely from all students except the course creator.
        </p>
      </>
    ),
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: [
        tabContentFormatter(DELETE_COURSE_TAB_1, 'Documentation', 0),
        tabContentFormatter(
          NOT_AVAILABLE.nimbus_free,
          'Request Customer support',
          1,
        ),
      ],
      nimbus_basic: [
        tabContentFormatter(DELETE_COURSE_TAB_1, 'Documentation', 0),
        tabContentFormatter(DELETE_COURSE_TAB_2, 'Request Customer support', 1),
      ],
      nimbus_enhanced: [
        tabContentFormatter(DELETE_COURSE_TAB_1, 'Documentation', 0),
        tabContentFormatter(DELETE_COURSE_TAB_2, 'Request Customer support', 1),
      ],
      nimbus_advanced: [
        tabContentFormatter(DELETE_COURSE_TAB_1, 'Documentation', 0),
        tabContentFormatter(DELETE_COURSE_TAB_2, 'Request Customer support', 1),
      ],
      on_premise: [
        tabContentFormatter(DELETE_COURSE_TAB_1, 'Documentation', 0),
        tabContentFormatter(DELETE_COURSE_TAB_2, 'Request Customer support', 1),
      ],
      cirrus: [
        tabContentFormatter(DELETE_COURSE_TAB_1, 'Documentation', 0),
        tabContentFormatter(DELETE_COURSE_TAB_2, 'Request Customer support', 1),
      ],
      cirrus_sn: [
        tabContentFormatter(DELETE_COURSE_TAB_1, 'Documentation', 0),
        tabContentFormatter(DELETE_COURSE_TAB_2, 'Request Customer support', 1),
      ],
      cirrus_se: [
        tabContentFormatter(DELETE_COURSE_TAB_1, 'Documentation', 0),
        tabContentFormatter(DELETE_COURSE_TAB_2, 'Request Customer support', 1),
      ],
      edgon: [
        tabContentFormatter(DELETE_COURSE_TAB_1, 'Documentation', 0),
        tabContentFormatter(DELETE_COURSE_TAB_2, 'Request Customer support', 1),
      ],
      edgon_sn: [
        tabContentFormatter(DELETE_COURSE_TAB_1, 'Documentation', 0),
        tabContentFormatter(DELETE_COURSE_TAB_2, 'Request Customer support', 1),
      ],
      edgon_se: [
        tabContentFormatter(DELETE_COURSE_TAB_1, 'Documentation', 0),
        tabContentFormatter(DELETE_COURSE_TAB_2, 'Request Customer support', 1),
      ],
      stratus: [
        tabContentFormatter(DELETE_COURSE_TAB_1, 'Documentation', 0),
        tabContentFormatter(DELETE_COURSE_TAB_2, 'Request Customer support', 1),
      ],
      else: MISCONFIGURED_SUBSCRIPTION,
    },
  },
  x_block_courses: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: [
        tabContentFormatter(
          NOT_AVAILABLE.nimbus_free,
          'Introduction to X-Blocks',
          0,
        ),
        tabContentFormatter(
          NOT_AVAILABLE.nimbus_free,
          'Implementation guide',
          1,
        ),
        tabContentFormatter(
          X_BLOCK_TAB_NOT_AVAILABLE_NIMBUS_FREE,
          'Innovative learning experiences',
          2,
        ),
      ],
      nimbus_basic: [
        tabContentFormatter(X_BLOCK_TAB_1, 'Introduction to X-Blocks', 0),
        tabContentFormatter(
          NOT_AVAILABLE.nimbus_basic,
          'Implementation guide',
          1,
        ),
        tabContentFormatter(
          X_BLOCK_TAB_3,
          'Innovative learning experiences',
          2,
        ),
      ],
      nimbus_enhanced: [
        tabContentFormatter(X_BLOCK_TAB_1, 'Introduction to X-Blocks', 0),
        tabContentFormatter(X_BLOCK_TAB_2, 'Implementation guide', 1),
        tabContentFormatter(
          X_BLOCK_TAB_3,
          'Innovative learning experiences',
          2,
        ),
      ],
      nimbus_advanced: [
        tabContentFormatter(X_BLOCK_TAB_1, 'Introduction to X-Blocks', 0),
        tabContentFormatter(X_BLOCK_TAB_2, 'Implementation guide', 1),
        tabContentFormatter(
          X_BLOCK_TAB_3,
          'Innovative learning experiences',
          2,
        ),
      ],
      on_premise: [
        tabContentFormatter(X_BLOCK_TAB_1, 'Introduction to X-Blocks', 0),
        tabContentFormatter(X_BLOCK_TAB_2, 'Implementation guide', 1),
        tabContentFormatter(
          X_BLOCK_TAB_3,
          'Innovative learning experiences',
          2,
        ),
      ],
      cirrus: [
        tabContentFormatter(X_BLOCK_TAB_1, 'Introduction to X-Blocks', 0),
        tabContentFormatter(X_BLOCK_TAB_2, 'Implementation guide', 1),
        tabContentFormatter(
          X_BLOCK_TAB_3,
          'Innovative learning experiences',
          2,
        ),
      ],
      cirrus_sn: [
        tabContentFormatter(X_BLOCK_TAB_1, 'Introduction to X-Blocks', 0),
        tabContentFormatter(X_BLOCK_TAB_2, 'Implementation guide', 1),
        tabContentFormatter(
          X_BLOCK_TAB_3,
          'Innovative learning experiences',
          2,
        ),
      ],
      cirrus_se: [
        tabContentFormatter(X_BLOCK_TAB_1, 'Introduction to X-Blocks', 0),
        tabContentFormatter(X_BLOCK_TAB_2, 'Implementation guide', 1),
        tabContentFormatter(
          X_BLOCK_TAB_3,
          'Innovative learning experiences',
          2,
        ),
      ],
      edgon: [
        tabContentFormatter(X_BLOCK_TAB_1, 'Introduction to X-Blocks', 0),
        tabContentFormatter(X_BLOCK_TAB_2, 'Implementation guide', 1),
        tabContentFormatter(
          X_BLOCK_TAB_3,
          'Innovative learning experiences',
          2,
        ),
      ],
      edgon_sn: [
        tabContentFormatter(X_BLOCK_TAB_1, 'Introduction to X-Blocks', 0),
        tabContentFormatter(X_BLOCK_TAB_2, 'Implementation guide', 1),
        tabContentFormatter(
          X_BLOCK_TAB_3,
          'Innovative learning experiences',
          2,
        ),
      ],
      edgon_se: [
        tabContentFormatter(X_BLOCK_TAB_1, 'Introduction to X-Blocks', 0),
        tabContentFormatter(X_BLOCK_TAB_2, 'Implementation guide', 1),
        tabContentFormatter(
          X_BLOCK_TAB_3,
          'Innovative learning experiences',
          2,
        ),
      ],
      stratus: [
        tabContentFormatter(X_BLOCK_TAB_1, 'Introduction to X-Blocks', 0),
        tabContentFormatter(X_BLOCK_TAB_2, 'Implementation guide', 1),
        tabContentFormatter(
          X_BLOCK_TAB_3,
          'Innovative learning experiences',
          2,
        ),
      ],
      else: MISCONFIGURED_SUBSCRIPTION,
    },
  },
  program_courses: {
    static: (
      <>
        <p>
          With the edunext alternative you will be able to enroll in a program
          and generate a program certificate. The functionality is based on a
          model course that acts as the program course; this course represents
          the collection of courses offered in the program. The model course
          will contain a configuration that links the courses in the program to
          the model course.
        </p>
        <h6>The setup solves the 2 challenges as follows:</h6>
        <p>
          Learners can enroll in this model course as they would any other
          course, and this enrollment automatically triggers enrollment in child
          courses. The final grade and certificate of the course model are
          awarded based on the final grades of the courses that are part of the
          program.
        </p>
      </>
    ),
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: [
        tabContentFormatter(NOT_AVAILABLE.nimbus_free, 'Activate LMS', 0),
        tabContentFormatter(NOT_AVAILABLE.nimbus_free, 'Documentation', 1),
      ],
      nimbus_basic: [
        tabContentFormatter(NOT_AVAILABLE.nimbus_basic, 'Activate LMS', 0),
        tabContentFormatter(NOT_AVAILABLE.nimbus_basic, 'Documentation', 1),
      ],
      nimbus_enhanced: [
        tabContentFormatter(NOT_AVAILABLE.nimbus_enhanced, 'Activate LMS', 0),
        tabContentFormatter(NOT_AVAILABLE.nimbus_enhanced, 'Documentation', 1),
      ],
      nimbus_advanced: [
        tabContentFormatter(PROGRAMS_TAB_1, 'Activate LMS', 0),
        tabContentFormatter(PROGRAMS_TAB_2, 'Documentation', 1),
      ],
      on_premise: [
        tabContentFormatter(PROGRAMS_TAB_1, 'Activate LMS', 0),
        tabContentFormatter(PROGRAMS_TAB_2, 'Documentation', 1),
      ],
      cirrus: [
        tabContentFormatter(PROGRAMS_TAB_1, 'Activate LMS', 0),
        tabContentFormatter(PROGRAMS_TAB_2, 'Documentation', 1),
      ],
      cirrus_sn: [
        tabContentFormatter(PROGRAMS_TAB_1, 'Activate LMS', 0),
        tabContentFormatter(PROGRAMS_TAB_2, 'Documentation', 1),
      ],
      cirrus_se: [
        tabContentFormatter(PROGRAMS_TAB_1, 'Activate LMS', 0),
        tabContentFormatter(PROGRAMS_TAB_2, 'Documentation', 1),
      ],
      edgon: [
        tabContentFormatter(PROGRAMS_TAB_1, 'Activate LMS', 0),
        tabContentFormatter(PROGRAMS_TAB_2, 'Documentation', 1),
      ],
      edgon_sn: [
        tabContentFormatter(PROGRAMS_TAB_1, 'Activate LMS', 0),
        tabContentFormatter(PROGRAMS_TAB_2, 'Documentation', 1),
      ],
      edgon_se: [
        tabContentFormatter(PROGRAMS_TAB_1, 'Activate LMS', 0),
        tabContentFormatter(PROGRAMS_TAB_2, 'Documentation', 1),
      ],
      stratus: [
        tabContentFormatter(PROGRAMS_TAB_1, 'Activate LMS', 0),
        tabContentFormatter(PROGRAMS_TAB_2, 'Documentation', 1),
      ],
      else: MISCONFIGURED_SUBSCRIPTION,
    },
  },
};
