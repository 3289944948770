/**
 *  SUBSCRIPTION CONSTANTS CONTROL CENTER
 *  In this file the subscription constants of the project are configured.
 *  In this file we will find ONLY the keys used in the My Subscription view.
 *
 *  WARNINGS:
 *            - When you need to include more than one HTML tag, they must be contained in
 *              a parent HTML tag, for example:
 *              Examples of incorrect code:
 *              (
 *                <h1>Hello World!</h1>
 *                <p>Lorem ipsum ... </p>
 *              )
 *
 *              Examples of correct code:
 *              (
 *                <>
 *                    <h1>Hello World!</h1>
 *                    <p>Lorem ipsum ... </p>
 *                </>
 *              )
 *
 *            - Make sure to preserve both the structure and the indentation, our format is:
 *              {
 *                section_name: {
 *                    static: (... Text|HTML),
 *                    variable: {
 *                        variable_1: (... Text|HTML),
 *                        ...
 *                    },
 *                },
 *                ...
 *              }
 */
import React from 'react';
import SupportInfo from '../components/supportInfo/SupportInfo';
import UpgradeSubscriptionButton from '../components/upgradeSubscriptionButton/UpgradeSubscriptionButton';

import { CANCEL_SUBSCRIPTION_FORM } from './modals.constants';

export const INACTIVE_SUBSCRIPTION = (
  <div style={{ textAlign: 'center', padding: '20px 0px' }}>
    <p className="text-danger">Your subscription is inactive.</p>
    <p className="d-flex justify-content-center">
      <UpgradeSubscriptionButton>
        REACTIVATE YOUR SUBSCRIPTION
      </UpgradeSubscriptionButton>
    </p>
  </div>
);

export const INACTIVE_STRATUS_SUBSCRIPTION = (
  <div style={{ textAlign: 'center', padding: '20px 0px' }}>
    <p className="text-danger">Your STRATUS subscription is inactive.</p>
  </div>
);

export const MISCONFIGURED_SUBSCRIPTION = (
  <p>
    This functionality is not available for your subscription, or your
    subscription may be missconfigured. Contact edunext support for more
    information.
  </p>
);

export const CHANGE_SUBSCRIPTION = (
  <div className="d-flex justify-content-center align-items-center">
    <UpgradeSubscriptionButton>
      Modify my Subscription
    </UpgradeSubscriptionButton>
    {CANCEL_SUBSCRIPTION_FORM}
  </div>
);

// It is used like this so that `SubscriptionVariableContent` can use `reactElementExtraProps`
export const UPGRADE_SUBSCRIPTION = {
  component: SupportInfo,
  props: {
    type: 'LITE',
  },
};

export const CONTACT_SUBSCRIPTION = (
  <p>
    If you need to make any changes to your subscription, please reach out to
    sales@edunext.co or your sales representative with all the details.
  </p>
);

export const SUBSCRIPTION_RESTRICTED_SSL = (
  <>
    <p>
      Your subscription is restricted to the SSL encryption provided by edunext.
    </p>
    <p>
      <UpgradeSubscriptionButton />
    </p>
  </>
);

export default {
  info_action_buttons: {
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: UPGRADE_SUBSCRIPTION,
      nimbus_basic: CHANGE_SUBSCRIPTION,
      nimbus_enhanced: CHANGE_SUBSCRIPTION,
      nimbus_advanced: CHANGE_SUBSCRIPTION,
      on_premise: CONTACT_SUBSCRIPTION,
      cirrus: CONTACT_SUBSCRIPTION,
      cirrus_sn: CONTACT_SUBSCRIPTION,
      cirrus_se: CHANGE_SUBSCRIPTION,
      edgon: CONTACT_SUBSCRIPTION,
      edgon_sn: CONTACT_SUBSCRIPTION,
      edgon_se: CHANGE_SUBSCRIPTION,
      stratus: CANCEL_SUBSCRIPTION_FORM,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
  },
};
