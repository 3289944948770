/**
 *  PLATFORM CONSTANTS CONTROL CENTER
 *  In this file the platform constants of the project are configured.
 *  In this file we will find ONLY the keys used in the Platform view.
 *
 *  WARNINGS:
 *            - When you need to include more than one HTML tag, they must be contained in
 *              a parent HTML tag, for example:
 *              Examples of incorrect code:
 *              (
 *                <h1>Hello World!</h1>
 *                <p>Lorem ipsum ... </p>
 *              )
 *
 *              Examples of correct code:
 *              (
 *                <>
 *                    <h1>Hello World!</h1>
 *                    <p>Lorem ipsum ... </p>
 *                </>
 *              )
 *
 *            - Make sure to preserve both the structure and the indentation, our format is:
 *              {
 *                section_name: {
 *                    static: (... Text|HTML),
 *                    variable: {
 *                        variable_1: (... Text|HTML),
 *                        ...
 *                    },
 *                },
 *                ...
 *              }
 */
import React from 'react';
import { Col, Row } from 'antd';

import ZohoForm from '../components/ZohoForm/ZohoForm';
import DynamicElement from '../components/DynamicElement/DynamicElement';
import tabContentFormatter from '../helpers/utils/tabContentFormatter/tabContentFormatter';

import supportIlustration from '../assets/supportIlustration.png';

import { NOT_AVAILABLE } from './messages.constants';

import {
  INACTIVE_SUBSCRIPTION,
  INACTIVE_STRATUS_SUBSCRIPTION,
  MISCONFIGURED_SUBSCRIPTION,
} from './subscription.constants';

import Styles from '../Common.module.scss';

const REQUEST_CUSTOMER_SUPPORT = (
  <Row justify="center" align="middle">
    <Col xl={{ span: 12 }} lg={{ span: 24 }}>
      <div style={{ textAlign: 'center' }}>
        <img
          src={supportIlustration}
          alt="intermediate"
          style={{ maxWidth: '400px', width: '100%' }}
        />
      </div>
    </Col>
    <Col xl={{ span: 12 }} lg={{ span: 24 }}>
      <div style={{ maxWidth: '600px', margin: '0 auto' }}>
        <p>
          If the user does not yet have an account on your LMS site and has
          not entered at least once, they will not be able to grant them
          the permissions of course creator.
        </p>
        <ZohoForm
          subject="Special STUDIO permissions request"
          showUserName={false}
          showEmail={false}
          showSubject={false}
          showOptions={false}
          showDescription={false}
          extraFields={[
            {
              id: 'email-user-permission-request',
              name: 'email-user-permission-request',
              label: 'Email for the user for which the permissions are requested',
              textHelp: 'Make sure that this same email has already been created in the LMS side,'
              + 'as the user for the edunext management console will be linked to the existing user in the LMS.',
              isRequired: true,
              validation: {
                type: 'email', warningOnly: false,
              },
            },
            {
              id: 'need-permission',
              name: 'need-permission',
              label: 'Do you need permissions added or removed?',
              type: 'radio',
              options: [
                'Added',
                'Removed',
              ],
              isRequired: true,
            },
            {
              id: 'select-permission',
              name: 'select-permission',
              label: 'Select the permission that needs to be added / removed',
              type: 'checkbox',
              options: [
                'Course creation permissions for a particular ORG',
                'Admin permissions for all courses in a particular ORG',
                'Library creation permissions',
              ],
              isRequired: false,
            },
            {
              id: 'shortcode-org-need-permissions',
              name: 'shortcode-org-need-permissions',
              label: 'Type the shortcode for the ORG where the permissions need to be applied',
              textHelp: 'If your request is for more than one ORG, add the different orgs separated by commas.',
              isRequired: true,
            },
            {
              id: 'is-user-already-registered',
              name: 'is-user-already-registered',
              label: 'Did you make sure that the user is already registered in your LMS site?',
              type: 'checkbox',
              options: [
                'Yes, the user is registered in your LMS site.',
              ],
              isRequired: true,
            },
          ]}
        />
      </div>
    </Col>
  </Row>
);

const ADD_NEW_COURSE_TEAM = (
  <Row justify="center" align="middle">
    <Col xs={24} md={12}>
      <ol>
        <br />
        <li>
          Go to
          <DynamicElement type="studio" text="STUDIO" />
          and login.
        </li>
        <br />
        <li>
          <b className={Styles.boldText}>
            Enter the course
          </b>
          where you want to add the new course team member.
        </li>
        <br />
        <li>
          Go to
          <b className={Styles.boldText}>
            Settings
          </b>
          located in the top menu.
        </li>
        <br />
        <li>
          Pull down the settings menu and click on
          <b className={Styles.boldText}>
            Course Team.
          </b>
        </li>
        <br />
        <li>
          Start adding member on the
          <b className={Styles.boldText}>
            New Team Member
          </b>
          button.
        </li>
      </ol>
    </Col>
    <Col xs={0} md={12}>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/0qAik9ufxOo"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </Col>
  </Row>
);

const VIEW_ALL_COURSE_TEAM_TAB_1 = (
  <Row justify="center" align="middle">
    <Col xs={24} md={12}>
      <ol>
        <br />
        <li>
          Go to
          <DynamicElement type="studio" text="STUDIO" />
          and login.
        </li>
        <br />
        <li>
          <b className={Styles.boldText}>
            Enter the course
          </b>
          where you want to add the new course team member.
        </li>
        <br />
        <li>
          Go to
          <b className={Styles.boldText}>
            Settings
          </b>
          located in the top menu.
        </li>
        <br />
        <li>
          Pull down the settings menu and click on
          <b className={Styles.boldText}>
            Course Team.
          </b>
        </li>
        <br />
        <li>
          In this section you will find the list of your team.
        </li>
        <br />
      </ol>
    </Col>
    <Col xs={0} md={12}>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/znDL1NeVwVU"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </Col>
  </Row>
);

const VIEW_ALL_COURSE_TEAM_TAB_2 = (
  <Row justify="center" align="middle">
    <Col xs={24} md={12}>
      <ol>
        <br />
        <li>
          <DynamicElement type="lms" text="Go to your LMS" />
          and login.
        </li>
        <br />
        <li>
          <b className={Styles.boldText}>
            Enter the course
          </b>
          where you want to add roles.
        </li>
        <br />
        <li>
          Go to the
          <b className={Styles.boldText}>
            Instructor tab.
          </b>
        </li>
        <br />
        <li>
          Go to the
          <b className={Styles.boldText}>
            Membership tab.
          </b>
        </li>
        <br />
        <li>
          Scroll until you reach the
          <b className={Styles.boldText}>
            Course Team Management
          </b>
          section.
        </li>
        <br />
      </ol>
    </Col>
    <Col xs={0} md={12}>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/zQXnjYSQLlo"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </Col>
  </Row>
);

const ADD_ROLES = (
  <Row justify="center" align="middle">
    <Col xs={24} md={12}>
      <ol>
        <br />
        <li>
          <DynamicElement type="lms" text="Go to your LMS" />
          and login.
        </li>
        <br />
        <li>
          <b className={Styles.boldText}>
            Enter the course
          </b>
          where you want to add roles.
        </li>
        <br />
        <li>
          Go to the
          <b className={Styles.boldText}>
            Instructor tab.
          </b>
        </li>
        <br />
        <li>
          Go to the
          <b className={Styles.boldText}>
            Membership tab.
          </b>
        </li>
        <br />
        <li>
          Scroll until you reach the
          <b className={Styles.boldText}>
            Course Team Management
          </b>
          section.
        </li>
        <br />
        <li>
          Select the role you want to provide to one of your members.
        </li>
        <br />
        <li>
          Add the
          <b className={Styles.boldText}>
            username or email
          </b>
          of one of your course team.
        </li>
        <br />
        <li>
          Click on button add.
        </li>
        <br />
      </ol>
    </Col>
    <Col xs={0} md={12}>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/LaIBIjgXdlk"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </Col>
  </Row>
);

export default {
  add_new_course_creator: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: [
        tabContentFormatter(NOT_AVAILABLE.nimbus_free, 'Request Customer support', 0),
      ],
      nimbus_basic: [
        tabContentFormatter(REQUEST_CUSTOMER_SUPPORT, 'Request Customer support', 0),
      ],
      nimbus_enhanced: [
        tabContentFormatter(REQUEST_CUSTOMER_SUPPORT, 'Request Customer support', 0),
      ],
      nimbus_advanced: [
        tabContentFormatter(REQUEST_CUSTOMER_SUPPORT, 'Request Customer support', 0),
      ],
      on_premise: [
        tabContentFormatter(REQUEST_CUSTOMER_SUPPORT, 'Request Customer support', 0),
      ],
      cirrus: [
        tabContentFormatter(REQUEST_CUSTOMER_SUPPORT, 'Request Customer support', 0),
      ],
      cirrus_sn: [
        tabContentFormatter(REQUEST_CUSTOMER_SUPPORT, 'Request Customer support', 0),
      ],
      cirrus_se: [
        tabContentFormatter(REQUEST_CUSTOMER_SUPPORT, 'Request Customer support', 0),
      ],
      edgon: [
        tabContentFormatter(REQUEST_CUSTOMER_SUPPORT, 'Request Customer support', 0),
      ],
      edgon_sn: [
        tabContentFormatter(REQUEST_CUSTOMER_SUPPORT, 'Request Customer support', 0),
      ],
      edgon_se: [
        tabContentFormatter(REQUEST_CUSTOMER_SUPPORT, 'Request Customer support', 0),
      ],
      stratus: [
        tabContentFormatter(REQUEST_CUSTOMER_SUPPORT, 'Request Customer support', 0),
      ],
      else: MISCONFIGURED_SUBSCRIPTION,
    },
  },
  add_new_course_team: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: [
        tabContentFormatter(ADD_NEW_COURSE_TEAM, 'Through STUDIO', 0),
      ],
      nimbus_basic: [
        tabContentFormatter(ADD_NEW_COURSE_TEAM, 'Through STUDIO', 0),
      ],
      nimbus_enhanced: [
        tabContentFormatter(ADD_NEW_COURSE_TEAM, 'Through STUDIO', 0),
      ],
      nimbus_advanced: [
        tabContentFormatter(ADD_NEW_COURSE_TEAM, 'Through STUDIO', 0),
      ],
      on_premise: [
        tabContentFormatter(ADD_NEW_COURSE_TEAM, 'Through STUDIO', 0),
      ],
      cirrus: [
        tabContentFormatter(ADD_NEW_COURSE_TEAM, 'Through STUDIO', 0),
      ],
      cirrus_sn: [
        tabContentFormatter(ADD_NEW_COURSE_TEAM, 'Through STUDIO', 0),
      ],
      cirrus_se: [
        tabContentFormatter(ADD_NEW_COURSE_TEAM, 'Through STUDIO', 0),
      ],
      edgon: [
        tabContentFormatter(ADD_NEW_COURSE_TEAM, 'Through STUDIO', 0),
      ],
      edgon_sn: [
        tabContentFormatter(ADD_NEW_COURSE_TEAM, 'Through STUDIO', 0),
      ],
      edgon_se: [
        tabContentFormatter(ADD_NEW_COURSE_TEAM, 'Through STUDIO', 0),
      ],
      stratus: [
        tabContentFormatter(ADD_NEW_COURSE_TEAM, 'Through STUDIO', 0),
      ],
      else: MISCONFIGURED_SUBSCRIPTION,
    },
  },
  view_all_course_team: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: [
        tabContentFormatter(VIEW_ALL_COURSE_TEAM_TAB_1, 'Through STUDIO', 0),
        tabContentFormatter(VIEW_ALL_COURSE_TEAM_TAB_2, 'From LMS instructor', 1),
      ],
      nimbus_basic: [
        tabContentFormatter(VIEW_ALL_COURSE_TEAM_TAB_1, 'Through STUDIO', 0),
        tabContentFormatter(VIEW_ALL_COURSE_TEAM_TAB_2, 'From LMS instructor', 1),
      ],
      nimbus_enhanced: [
        tabContentFormatter(VIEW_ALL_COURSE_TEAM_TAB_1, 'Through STUDIO', 0),
        tabContentFormatter(VIEW_ALL_COURSE_TEAM_TAB_2, 'From LMS instructor', 1),
      ],
      nimbus_advanced: [
        tabContentFormatter(VIEW_ALL_COURSE_TEAM_TAB_1, 'Through STUDIO', 0),
        tabContentFormatter(VIEW_ALL_COURSE_TEAM_TAB_2, 'From LMS instructor', 1),
      ],
      on_premise: [
        tabContentFormatter(VIEW_ALL_COURSE_TEAM_TAB_1, 'Through STUDIO', 0),
        tabContentFormatter(VIEW_ALL_COURSE_TEAM_TAB_2, 'From LMS instructor', 1),
      ],
      cirrus: [
        tabContentFormatter(VIEW_ALL_COURSE_TEAM_TAB_1, 'Through STUDIO', 0),
        tabContentFormatter(VIEW_ALL_COURSE_TEAM_TAB_2, 'From LMS instructor', 1),
      ],
      cirrus_sn: [
        tabContentFormatter(VIEW_ALL_COURSE_TEAM_TAB_1, 'Through STUDIO', 0),
        tabContentFormatter(VIEW_ALL_COURSE_TEAM_TAB_2, 'From LMS instructor', 1),
      ],
      cirrus_se: [
        tabContentFormatter(VIEW_ALL_COURSE_TEAM_TAB_1, 'Through STUDIO', 0),
        tabContentFormatter(VIEW_ALL_COURSE_TEAM_TAB_2, 'From LMS instructor', 1),
      ],
      edgon: [
        tabContentFormatter(VIEW_ALL_COURSE_TEAM_TAB_1, 'Through STUDIO', 0),
        tabContentFormatter(VIEW_ALL_COURSE_TEAM_TAB_2, 'From LMS instructor', 1),
      ],
      edgon_sn: [
        tabContentFormatter(VIEW_ALL_COURSE_TEAM_TAB_1, 'Through STUDIO', 0),
        tabContentFormatter(VIEW_ALL_COURSE_TEAM_TAB_2, 'From LMS instructor', 1),
      ],
      edgon_se: [
        tabContentFormatter(VIEW_ALL_COURSE_TEAM_TAB_1, 'Through STUDIO', 0),
        tabContentFormatter(VIEW_ALL_COURSE_TEAM_TAB_2, 'From LMS instructor', 1),
      ],
      stratus: [
        tabContentFormatter(VIEW_ALL_COURSE_TEAM_TAB_1, 'Through STUDIO', 0),
        tabContentFormatter(VIEW_ALL_COURSE_TEAM_TAB_2, 'From LMS instructor', 1),
      ],
      else: MISCONFIGURED_SUBSCRIPTION,
    },
  },
  add_roles: {
    static: <div />,
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: [
        tabContentFormatter(ADD_ROLES, 'Through LMS', 0),
      ],
      nimbus_basic: [
        tabContentFormatter(ADD_ROLES, 'Through LMS', 0),
      ],
      nimbus_enhanced: [
        tabContentFormatter(ADD_ROLES, 'Through LMS', 0),
      ],
      nimbus_advanced: [
        tabContentFormatter(ADD_ROLES, 'Through LMS', 0),
      ],
      on_premise: [
        tabContentFormatter(ADD_ROLES, 'Through LMS', 0),
      ],
      cirrus: [
        tabContentFormatter(ADD_ROLES, 'Through LMS', 0),
      ],
      cirrus_sn: [
        tabContentFormatter(ADD_ROLES, 'Through LMS', 0),
      ],
      cirrus_se: [
        tabContentFormatter(ADD_ROLES, 'Through LMS', 0),
      ],
      edgon: [
        tabContentFormatter(ADD_ROLES, 'Through LMS', 0),
      ],
      edgon_sn: [
        tabContentFormatter(ADD_ROLES, 'Through LMS', 0),
      ],
      edgon_se: [
        tabContentFormatter(ADD_ROLES, 'Through LMS', 0),
      ],
      stratus: [
        tabContentFormatter(ADD_ROLES, 'Through LMS', 0),
      ],
      else: MISCONFIGURED_SUBSCRIPTION,
    },
  },
};
