/**
 *  REPORT AND ANALYTICS CONSTANTS CONTROL CENTER
 *  In this file the report and analytics constants of the project are configured.
 *  In this file we will find ONLY the keys used in the My Site > Report and Analytics view.
 *
 *  WARNINGS:
 *            - When you need to include more than one HTML tag, they must be contained in
 *              a parent HTML tag, for example:
 *              Examples of incorrect code:
 *              (
 *                <h1>Hello World!</h1>
 *                <p>Lorem ipsum ... </p>
 *              )
 *
 *              Examples of correct code:
 *              (
 *                <>
 *                    <h1>Hello World!</h1>
 *                    <p>Lorem ipsum ... </p>
 *                </>
 *              )
 *
 *            - Make sure to preserve both the structure and the indentation, our format is:
 *              {
 *                section_name: {
 *                    static: (... Text|HTML),
 *                    variable: {
 *                        variable_1: (... Text|HTML),
 *                        ...
 *                    },
 *                },
 *                ...
 *              }
 */
import React from 'react';
import BannergeDenied from '../components/bannerSiteInfo/bannerDenied/BannerDenied';
import OnboardingBox from '../components/Onboarding/OnboardingBox/OnboardingBox';

import reportAnalyticsUsage from '../assets/onboardings/report-analytics-usage.png';
import reportAnalyticsReport from '../assets/onboardings/report-analytics-reports.png';
import reportAnalyticsAnalytics from '../assets/onboardings/report-analytics-analytics.png';
import emailMonitoring from '../assets/onboardings/email-monitoring.png';
import edxInsights from '../assets/edxInsights.gif';

import Styles from '../Common.module.scss';

import {
  GOTO_MANAGE_DASHBOARD,
  GOTO_MANAGE_EMAIL,
  GOTO_MANAGE_REPORTS_MY_REPORTS,
} from './iframes.constants';
import { NOT_AVAILABLE } from './messages.constants';
import {
  INACTIVE_SUBSCRIPTION,
  INACTIVE_STRATUS_SUBSCRIPTION,
  MISCONFIGURED_SUBSCRIPTION,
} from './subscription.constants';

const GOTO_MANAGE_REPORTS = (
  <>
    <p>
      <a
        rel="noopener noreferrer"
        className={Styles.link}
        href={`${process.env.MANAGE_ROOT_URL}reports/new-report`}
        target="_blank"
      >
        Create a new report
      </a>
    </p>
    {GOTO_MANAGE_REPORTS_MY_REPORTS}
  </>
);

export default {
  usage_unica: {
    static: (
      <>
        <p>
          The active users dashboard shows the number of active users in your
          site.
        </p>
        <p>
          A visitor is counted as an active user only when they visit the
          courseware or the discussions sections inside one of your courses.
        </p>
        <p>
          Each person is only counted once per site, per period, regardles of
          the number of visits or the number of courses they interact with.
        </p>
      </>
    ),
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: GOTO_MANAGE_DASHBOARD,
      nimbus_enhanced: GOTO_MANAGE_DASHBOARD,
      nimbus_advanced: GOTO_MANAGE_DASHBOARD,
      on_premise: <BannergeDenied tier="On Premise" />,
      cirrus: <BannergeDenied tier="Cirrus" />,
      cirrus_sn: <BannergeDenied tier="Cirrus" />,
      cirrus_se: <BannergeDenied tier="Cirrus" />,
      edgon: <BannergeDenied tier="EDGON" />,
      edgon_sn: <BannergeDenied tier="EDGON" />,
      edgon_se: <BannergeDenied tier="EDGON" />,
      stratus: <BannergeDenied tier="Stratus" />,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        Monitor the number of
        <b className={Styles.onboardingTitleBlue}>
          active learners in your cloud
        </b>
        {' '}
        based subscription - Nimbus
      </>
    ),
    onboardingSubtitle: <>Stay on top of your users&apos; activity.</>,
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            The active users dashboard shows the number of active users in your
            site.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            A visitor is counted as an active user only when they visit the
            courseware or the discussions sections inside one of your courses.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Each person is only counted once per site, per period, regardles of
            the number of visits or the number of courses they interact with.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: reportAnalyticsUsage,
  },
  reports_unica: {
    static: (
      <p>
        In addition to Open edX native reporting capabilities, which work at the
        course level and are available from the Instructor dashboard in the LMS,
        edunext has built some additional reporting capabilities that simplify
        accessing and managing the records in your site.
      </p>
    ),
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced: GOTO_MANAGE_REPORTS,
      nimbus_advanced: GOTO_MANAGE_REPORTS,
      on_premise: <BannergeDenied tier="On Premise" />,
      cirrus: <BannergeDenied tier="Cirrus" />,
      cirrus_sn: <BannergeDenied tier="Cirrus" />,
      cirrus_se: <BannergeDenied tier="Cirrus" />,
      edgon: <BannergeDenied tier="EDGON" />,
      edgon_sn: <BannergeDenied tier="EDGON" />,
      edgon_se: <BannergeDenied tier="EDGON" />,
      stratus: <BannergeDenied tier="Stratus" />,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        Create cross
        <b className={Styles.onboardingTitleBlue}>site reports</b>
        {' '}
        for the
        activity in your site
      </>
    ),
    onboardingSubtitle: (
      <>
        Get the information fast and conveniently
        <br />
        and make decisions based on data.
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            Open edX has native reporting capabilities, which work at the course
            level and are available from the Instructor panel in the LMS.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            In this section you can access additional reporting capabilities
            that simplify access and management of records on your site created
            by edunext.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            This functionality is limited to cloud based Nimbus subscriptions.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: reportAnalyticsReport,
  },
  analytics_unica: {
    static: (
      <>
        <p>
          The
          {' '}
          <strong>Open edX insights</strong>
          {' '}
          analytics application computes
          actionable information from your course runs, and presents them
          visually, including:
        </p>
        <ul>
          <li>The dynamics of the number of enrolled students</li>
          <li>The demographics of the learners population</li>
          <li>The level of engagement in each course over time</li>
          <li>
            The statistics for viewers in each video along the video&apos;s
            length
          </li>
          <li>
            The performance of the students in the different scored components
            of the course.
          </li>
          <li>The answer breakdown for multiple choice questions</li>
        </ul>
        <i>
          Disclaimer: Analytics has been deprecated, for now is working in
          legacy mode.
        </i>
        <br />
        <img
          width="60%"
          src={edxInsights}
          alt="Insights in open edX"
          style={{ maxWidth: '60%' }}
        />
      </>
    ),
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced: NOT_AVAILABLE.nimbus_enhanced,
      nimbus_advanced: (
        <p>
          <a
            href="https://insights.edunext.co"
            target="_blank"
            className={Styles.linkNormalButton}
            rel="noreferrer"
          >
            Launch Open edX Insights
          </a>
        </p>
      ),
      on_premise: <BannergeDenied tier="On Premise" />,
      cirrus: <BannergeDenied tier="Cirrus" />,
      cirrus_sn: <BannergeDenied tier="Cirrus" />,
      cirrus_se: <BannergeDenied tier="Cirrus" />,
      edgon: <BannergeDenied tier="EDGON" />,
      edgon_sn: <BannergeDenied tier="EDGON" />,
      edgon_se: <BannergeDenied tier="EDGON" />,
      stratus: <BannergeDenied tier="Stratus" />,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        Open edX Insights will help your initiative improve by
        <b className={Styles.onboardingTitleBlue}>
          understanding who your learners are and how they interact with your
          courses
        </b>
      </>
    ),
    onboardingSubtitle: (
      <>
        &quot;It is a capital mistake to theorize before one has
        <br />
        data.&quot; - Sherlock Holmes
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            The Open edX insights application computes information from your
            course runs, and presents it visually
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            Actionable information about the learners demographics, the
            engagement of learners in the course and the performance in graded
            and ungraded components.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            This functionality is limited to cloud based Nimbus Advanced
            subscriptions. Soon there will be new data analytics options for all
            types of subscriptions.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: reportAnalyticsAnalytics,
  },
  email_monitoring_unica: {
    static: (
      <>
        <p>
          Achieving Email delivery rates of 100% can be very challenging.
          Specially for the kind of automated / unrequested emails that the
          platform sends to leaerners on your behalf, which may be filtered as
          spam by the learner&apos;s email server.
        </p>
        <p>
          In order to help you keep track of how emails are being delivered and
          provide support to specific learners that may be reporting a missing
          email, edunext has added an email monitoring capability.
        </p>
      </>
    ),
    variable: {
      inactive_stratus_subscription: INACTIVE_STRATUS_SUBSCRIPTION,
      inactive_subscription: INACTIVE_SUBSCRIPTION,
      nimbus_free: NOT_AVAILABLE.nimbus_free,
      nimbus_basic: NOT_AVAILABLE.nimbus_basic,
      nimbus_enhanced: GOTO_MANAGE_EMAIL,
      nimbus_advanced: GOTO_MANAGE_EMAIL,
      on_premise: <BannergeDenied tier="On Premise" />,
      cirrus: <BannergeDenied tier="Cirrus" />,
      cirrus_sn: <BannergeDenied tier="Cirrus" />,
      cirrus_se: <BannergeDenied tier="Cirrus" />,
      edgon: <BannergeDenied tier="EDGON" />,
      edgon_sn: <BannergeDenied tier="EDGON" />,
      edgon_se: <BannergeDenied tier="EDGON" />,
      stratus: <BannergeDenied tier="Stratus" />,
      else: MISCONFIGURED_SUBSCRIPTION,
    },
    onboardingTitle: (
      <>
        <b className={Styles.onboardingTitleBlue}>
          Monitor the delivery of your emails
        </b>
        {' '}
        and ensure effective communication with your learners
      </>
    ),
    onboardingSubtitle: (
      <>
        Find out the success rate in all the
        <br />
        emails sent from the platform
      </>
    ),
    onboardingBox: (
      <>
        <OnboardingBox>
          <p>
            &quot;Email delivery rates of 100% can be hard to get. Specially for
            the kind of automated / unrequested emails that the platform sends
            to leaerners on your behalf, which may be filtered as spam by the
            learner&apos;s email server.&quot;
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            This monitoring capability allows you to keep track of the emails
            delivery status and provide support to specific learners that may be
            reporting a missing email.
          </p>
        </OnboardingBox>
        <OnboardingBox>
          <p>
            This functionality is limited to cloud based Nimbus Advanced
            subscriptions.
          </p>
        </OnboardingBox>
      </>
    ),
    onboardingImage: emailMonitoring,
  },
};
